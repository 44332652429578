import axios from 'axios';
import config from '../../assets/configUnirampApi.json';
import store from '../../store/index';

const { baseUrl, unirampApiKey, headerFieldName } = config;

const getFromRedux = () => {
  const state = store.getState();
  const { currentConfig } = state.uniramp;
  return currentConfig;
};

const axiosOptions = axios.create({
  baseURL: baseUrl,
});

axiosOptions.interceptors.request.use(
  async (config) => {
    const apiKey = getFromRedux()?.apiKey;

    if (apiKey) {
      config.headers = {
        ...config.headers,
        [`${headerFieldName}`]: apiKey,
      };
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

// axiosOptions.interceptors.response.use(
//   (res) => {
//     // res.data = { ...res.data };
//     return res;
//   },
//   (error) => {
//     const expectedErrors =
//       error.response &&
//       error.response.status >= 400 &&
//       error.response.status < 500;

//     if (!expectedErrors) {
//       console.log('error: ', error);
//     }
//     return Promise.reject(error);
//   }
// );

const axiosService = {
  get: axiosOptions.get,
  post: axiosOptions.post,
  put: axiosOptions.put,
  patch: axiosOptions.patch,
  delete: axiosOptions.delete,
};

export default axiosService;
