import { useState } from 'react';
import { RenderImg } from './serviceComponents/renderImg';
import { CheckMarkSVG, SearchSVG } from '../assets/svgStore';
import { checkedBg } from '../services/checkedBg';
import { networkOrder } from '../assets/networkOrder';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import { useLazyGetCurrentFiatByIdQuery } from '../store/uniramp/uniramp.api';
import { sizeForPopup } from '../assets/sizeForPopup';

export default function MenuCurrencies({ typeCurrency, setIsOpen }) {
  const dispatch = useDispatch();
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());

  const listFiat = useSelector(unirampSelectors.getFiatList());
  const currentFiat = useSelector(unirampSelectors.getCurrentFiat());
  const listCrypto = useSelector(unirampSelectors.getCryptoList());
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());

  const [currentFiatById] = useLazyGetCurrentFiatByIdQuery();

  const currenciesList = typeCurrency === 'fiat' ? listFiat : listCrypto;
  const currentCurrency = typeCurrency === 'fiat' ? currentFiat : currentCrypto;

  const [filterCurrency, setFilterCurrency] = useState('');

  const [selectedNetwork, setSelectedNetwork] = useState(networkOrder[0]);

  const onClickSelectedCurrency = async (currency) => {
    if (typeCurrency === 'fiat') {
      await currentFiatById(currency.id);
    } else {
      dispatch(unirampActions.setCurrentCrypto(currency));
    }
  };

  return (
    <>
      <div
        className={` flex flex-col pt-5 pb-4`}
        style={{
          width: sizeForPopup?.currencyMenu?.width,
          height: sizeForPopup?.currencyMenu?.height,
        }}
      >
        <h3
          className={`mx-auto mb-4 text-base`}
          style={{ color: currentTheme.text.accent }}
        >
          {typeCurrency === 'fiat'
            ? currentLocale['Select Currency']
            : currentLocale['Select token']}
        </h3>

        {/* ------------------------------------------------------ */}
        {typeCurrency === 'crypto' && (
          <div className="flex justify-evenly mb-3">
            {networkOrder.map((networkButton, index) => (
              <button
                key={index + '1'}
                className={`cursor-pointer block font-bold rounded-lg border px-3 text-base`}
                style={{
                  color: currentTheme?.text?.accent,
                  borderColor:
                    networkButton?.net === selectedNetwork?.net
                      ? currentConfig?.colBtn
                      : currentTheme?.border?.passive,
                  backgroundColor:
                    networkButton?.net === selectedNetwork?.net
                      ? currentTheme.bg.main
                      : currentTheme.bg.main,
                }}
                onClick={() => {
                  setSelectedNetwork((prevNetwork) =>
                    prevNetwork?.net === networkButton.net
                      ? null
                      : networkButton
                  );
                }}
              >
                {networkButton?.name}
              </button>
            ))}
          </div>
        )}
        {/* ------------------------------------------------------ */}

        <div
          className=" w-full px-4 h-fit mb-4"
          style={{ position: 'relative' }}
        >
          <label style={{ position: 'absolute', top: 16, left: 30 }}>
            <SearchSVG />
          </label>
          <input
            type="text"
            placeholder="Search..."
            className={` w-full outline-none border rounded-full py-2 pl-12 pr-20 my-2 text-base`}
            value={filterCurrency}
            onChange={(e) => {
              setFilterCurrency(e.target.value);
            }}
            style={{
              color: currentTheme.text.accent,
              backgroundColor: currentTheme.bg.main,
              borderColor: currentTheme?.border?.passive,
            }}
          />
        </div>
        <div className={` h-[100%] overflow-auto flex flex-col gap-1 mx-4`}>
          {currenciesList &&
            currenciesList
              .filter((el) => {
                if (typeCurrency === 'crypto' && selectedNetwork) {
                  if (el?.chain.includes(selectedNetwork?.net)) {
                    return el;
                  } else if (
                    selectedNetwork?.net === 'non-evm' &&
                    !['eth', 'poly', 'bsc'].includes(el?.chain)
                  ) {
                    return { el };
                  }
                } else return el;
              })
              .filter((el) => {
                if (typeCurrency === 'fiat') {
                  if (
                    el?.id
                      .toLowerCase()
                      .includes(filterCurrency.toLowerCase()) ||
                    el?.name
                      .toLowerCase()
                      .includes(filterCurrency.toLowerCase())
                  ) {
                    return el;
                  }
                } else {
                  if (
                    el?.ticker
                      .toLowerCase()
                      .includes(filterCurrency.toLowerCase()) ||
                    el?.name
                      .toLowerCase()
                      .includes(filterCurrency.toLowerCase())
                  ) {
                    return el;
                  }
                }
              })
              .map((currency, index) => {
                return (
                  <div
                    className={`flex justify-between item-center transition-all cursor-pointer p-2 rounded-lg items-center`}
                    onClick={() => {
                      onClickSelectedCurrency(currency);
                      // setIsOpen(false);
                      dispatch(unirampActions.setIsClosePopup(true));
                    }}
                    key={index}
                    style={{ color: currentTheme.text.accent }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        checkedBg(currentConfig.colBg) + '26')
                    }
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                  >
                    <div
                      className=" flex items-center justify-center gap-3"
                      style={{ color: checkedBg(currentConfig?.colBg) }}
                    >
                      <RenderImg currency={currency} />
                      <div className=" flex flex-col items-start">
                        <p
                          className=" text-[16px] font-bold"
                          style={{ color: currentTheme?.text?.accent }}
                        >
                          {typeCurrency === 'fiat'
                            ? currency?.id?.toUpperCase()
                            : currency?.ticker?.toUpperCase()}
                        </p>
                        <p
                          className=" text-[12px] whitespace-nowrap"
                          style={{ color: currentTheme?.text?.plain }}
                        >
                          {currency?.name}
                        </p>
                      </div>
                    </div>
                    {typeCurrency === 'fiat' &&
                      currency?.id === currentCurrency?.id && (
                        <div
                          className=" w-5 h-4 ml-3"
                          style={{ color: checkedBg(currentConfig?.colBg) }}
                        >
                          <CheckMarkSVG />
                        </div>
                      )}
                    {typeCurrency === 'crypto' ? (
                      currency?.ticker === currentCurrency?.ticker &&
                      currency?.chain === currentCurrency?.chain ? (
                        <div className="flex items-center  ">
                          <p
                            className=" text-[12px] uppercase"
                            style={{ color: currentTheme?.text?.plain }}
                          >
                            {currency?.chain}
                          </p>
                          <div
                            className=" w-5 h-4 ml-3 "
                            style={{
                              color: checkedBg(currentConfig?.colBg),
                            }}
                          >
                            <CheckMarkSVG />
                          </div>
                        </div>
                      ) : (
                        <p
                          className=" text-[12px] uppercase"
                          style={{ color: currentTheme?.text?.plain }}
                        >
                          {currency?.chain}
                        </p>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
}
