import React, { useEffect, useRef } from 'react';
import AcceptButton from './components/acceptButton';
import ChoicePaymentBox from './components/choicePaymentBox';
import CurrencyInfoBox from './components/currencyInfoBox';
import InfoBox from './components/infoBox';
import NavigationBox from './components/navigationBox';
import { Spinner } from './components/serviceComponents/spinner';
import TitleBox from './components/titleBox';

import { TransactionHandler } from './components/transactionHandler';
import { useSelector } from 'react-redux';
import unirampSelectors from './store/uniramp/uniramp.selector';
import { checkedBg } from './services/checkedBg';

export const MainScreen = () => {
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  const isAuth = useSelector(unirampSelectors.getIsAuth());

  const currentPayment = useSelector(unirampSelectors.getCurrentPayment());
  const pageBySell = useSelector(unirampSelectors.getPageBySell());

  const refMain = useRef(null);
  useEffect(() => {
    if (
      refMain &&
      refMain?.current?.style &&
      refMain?.current?.style?.overflow !== 'hidden'
    ) {
      refMain.current.style = {
        ...refMain.current.style,
        overflow: 'hidden',
        width: '510px',
      };
    }
  }, [refMain?.current?.overflow]);

  return (
    <>
      {currentTheme && (
        <>
          <div
            className=" rounded-lg w-fit h-fit"
            style={{
              boxShadow:
                '0px 10px 15px -1px rgba(0,0,0,0.1), 0px -5px 6px -5px rgba(0,0,0,0.1)',
            }}
          >
            <div
              ref={refMain}
              className={`w-[364px] max-w-[364px] min-w-[364px] h-[510px] rounded-lg text-cyan-600 p-4 relative overflow-hidden`}
              style={{
                backgroundColor: currentConfig.colBg,
                boxShadow:
                  '0px 10px 15px -1px rgba(0,0,0,0.1), 0px -5px 6px -5px rgba(0,0,0,0.1)',
              }}
            >
              <NavigationBox />
              {!currentPayment ? (
                isAuth ? (
                  <div className="h-[410px] flex justify-center items-center">
                    <Spinner bgColor={checkedBg(currentConfig?.colBg)} />
                  </div>
                ) : (
                  <div
                    className="h-[410px] flex flex-col justify-center items-center gap-5 text-lg font-bold"
                    style={{ color: checkedBg(currentConfig?.colBg) }}
                  >
                    <p>{currentLocale['Invalid API Key']}</p>
                  </div>
                )
              ) : (
                <>
                  {pageBySell === 'Buy' && (
                    <>
                      <CurrencyInfoBox
                        type={'spend'}
                        text={currentLocale['Spend']}
                        typeCurrency={'fiat'}
                      />
                      <CurrencyInfoBox
                        type={'get'}
                        text={currentLocale['Receive']}
                        typeCurrency={'crypto'}
                      />
                    </>
                  )}
                  {/* {pageBySell === 'Sell' && (
                <>
                  <CurrencyInfoBox
                    type={'get'}
                    text={currentLocale['Spend']}
                    typeCurrency={'crypto'}
                  />
                  <CurrencyInfoBox
                    type={'spend'}
                    text={currentLocale['Receive']}
                    typeCurrency={'fiat'}
                  />
                </>
              )} */}
                  <InfoBox />
                  <TitleBox />
                  <ChoicePaymentBox />
                  <AcceptButton typeCurrency={'crypto'} />
                </>
              )}
              <div
                style={{ color: currentTheme.text.accent }}
                className="text-[12px]  text-center pt-2"
              >
                {currentLocale?.PoweredBy}

                <a
                  className="underline  decoration-solid"
                  href="http://uniramp.com"
                >
                  {currentLocale?.Uniramp}
                </a>
              </div>
              <TransactionHandler />
            </div>
          </div>
        </>
      )}
    </>
  );
};
