import { useDispatch } from 'react-redux';
import { defaultConfigStore } from '../assets/storage';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import en from '../locales/en.json';
import { themesStorage } from '../assets/themesStorage';
import { useEffect, useState } from 'react';

export const useInitialProperties = (props) => {
  // console.log('props in useInitialProps', props);
  const dispatch = useDispatch();
  const [propsConf, setPropsConf] = useState(null);
  useEffect(() => {
    let temp = null;
    if (props) {
      temp = props?.config
        ? props?.config
        : props?.domElement.getAttributeNames().includes('data-config')
        ? props.domElement
            .getAttribute('data-config')
            .split(', ')
            .map((el) => el.split(':'))
            .reduce((acc, el) => {
              return { ...acc, [el[0]]: el[1] };
            }, {})
        : defaultConfigStore;
    }

    setPropsConf(temp);
    dispatch(unirampActions.setCurrentConfig({ ...temp }));

    const currentLocale = temp?.locale === 'en' ? en : en;
    dispatch(unirampActions.setCurrentLocale(currentLocale));

    const currentTheme = {
      ...themesStorage.find((el) => el.code === temp.theme),
    }.colors;
    dispatch(unirampActions.setCurrentTheme(currentTheme));

    // const currentAmount = temp?.initAmountFiat;
    // dispatch(unirampActions.setCurrentAmount(currentAmount));
  }, [dispatch, props]);

  return { propsConf };
};
