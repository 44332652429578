import { useCallback, useEffect } from 'react';
import { db } from '../../firebase.config';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { useDispatch, useSelector } from 'react-redux';
import { unirampActions } from '../store/uniramp/uniramp.slice';

export const useGetTransactions = () => {
  const dispatch = useDispatch();

  const wallet = useSelector(unirampSelectors.getWallet());
  const isAuthFirebase = useSelector(unirampSelectors.getIsAuthFirebase());

  const listenerHandler = useCallback(async () => {
    let i = 0;
    const q = wallet
      ? query(collection(db, 'transactions'), where('wallet', '==', wallet))
      : null;

    onSnapshot(q, (snapshot) => {
      const newTransactions = [];
      snapshot.forEach((doc) => {
        newTransactions.push(doc.data());
      });
      snapshot.docChanges().forEach((change) => {
        if (i && change.type === 'added') {
          dispatch(unirampActions.setCurrentTransactionFb(change.doc.data()));
        }
      });
      i = 1;
    });
  }, [wallet, dispatch]);

  useEffect(() => {
    if (wallet && isAuthFirebase) {
      listenerHandler();
    } else {
      if (!wallet) {
        dispatch(unirampActions.setIsAuthFirebase(false));
      }
    }
  }, [wallet, dispatch, isAuthFirebase, listenerHandler]);
};
