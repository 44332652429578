import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { DrawerComponent } from './serviceComponents/drawerComponent';
import { TransactionState } from './transactionState';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { useEffect } from 'react';
import { unirampActions } from '../store/uniramp/uniramp.slice';

export const TransactionHandler = () => {
  const dispatch = useDispatch();

  const currentTransaction = useSelector(
    unirampSelectors.getCurrentTransactionFb()
  );
  const currentTransactionId = useSelector(
    unirampSelectors.getCurrentTransactionId()
  );
  const isOpenState = useSelector(unirampSelectors.getIsOpenTransactionState());

  const setIsOpenState = (value) => {
    dispatch(unirampActions.setIsOpenTransactionState(value));
  };

  // const [isOpenState, setIsOpenState] = useState(false);

  // useEffect(() => {
  //   dispatch(unirampActions.setIsOpenTransactionState(isOpenState));
  // }, [dispatch, isOpenState]);

  useEffect(() => {
    if (
      currentTransactionId &&
      currentTransaction &&
      // currentTransaction?.id === currentTransactionId &&
      !['new'].includes(currentTransaction?.data?.status)
    ) {
      dispatch(unirampActions.setIsOpenIFrame(false));
      setIsOpenState(true);
      dispatch(unirampActions.setIsOpenTransactionState(true));
    }
  }, [currentTransaction, currentTransactionId, dispatch]);

  return (
    <DrawerComponent
      isOpen={isOpenState}
      setIsOpen={setIsOpenState}
      position={'down'}
      menuSize={'100%'}
      closeBtn={false}
    >
      <TransactionState />
    </DrawerComponent>
  );
};
