import React from 'react';

export const Spinner = ({ bgColor }) => {
  return (
    <>
      {/* {backgroundColorState && ( */}
      <div className="spinner">
        <div className="spinner-div" style={{ backgroundColor: bgColor }}></div>
        <div className="spinner-div" style={{ backgroundColor: bgColor }}></div>
        <div className="spinner-div" style={{ backgroundColor: bgColor }}></div>
        <div className="spinner-div" style={{ backgroundColor: bgColor }}></div>
      </div>
      {/* )} */}
    </>
  );
};
