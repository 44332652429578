import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: 'AIzaSyBCEgVO_SvYO5NlR7GDzakb2VVzjeUK4xY',
//   authDomain: 'uniramp-beta.firebaseapp.com',
//   databaseURL:
//     'https://uniramp-beta-default-rtdb.europe-west1.firebasedatabase.app',
//   projectId: 'uniramp-beta',
//   storageBucket: 'uniramp-beta.appspot.com',
//   messagingSenderId: '163322524572',
//   appId: '1:163322524572:web:0ff12ce58e546a3034ce59',
//   measurementId: 'G-ND3JDE004P',
// };
const firebaseConfig = {
  apiKey: 'AIzaSyARfZwWjW01mUYtEzsxWelm-iah3BnXHVY',
  authDomain: 'uniramp-beta-staging.firebaseapp.com',
  projectId: 'uniramp-beta-staging',
  storageBucket: 'uniramp-beta-staging.appspot.com',
  messagingSenderId: '700795716603',
  appId: '1:700795716603:web:4bbd360423e1b1dc4a815e',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
