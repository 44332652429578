import { themesStorage } from './themesStorage';
import configUnirampApi from './configUnirampApi.json';

export const defaultConfigStore = () => {
  const { colors, code } = themesStorage[0];
  return {
    theme: code,
    colBg: colors.bg.main,
    colBtn: colors.btn.bgBtn,
    token: 'eth',
    chain: 'eth',
    apiKey: configUnirampApi?.unirampApiKey,
  };
};
