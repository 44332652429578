import React from 'react';
import { BaseSetting } from './baseSetting';
import { ButtonApply } from './buttonApply';
import { colorPage } from '../../assets/colorSet';
import { FullLogoSVG } from '../../../widget/assets/svgStore';
import { usePageContext } from '../../pageConfig';

export const Configuration = (props) => {
  const { pageLocale } = usePageContext();
  return (
    <>
      {colorPage && (
        <div className={`flex flex-col`}>
          <div className=" w-40 h-5 mb-10" style={{ color: '#000000' }}>
            <FullLogoSVG />
          </div>

          <h3 className={`uppercase font-bold text-[${colorPage.title}] mb-10`}>
            {pageLocale['Configuration']}
          </h3>
          <BaseSetting config={props.config} setConfig={props.setConfig} />
          {/* <AdvancedSetting /> */}
          {/* <ButtonApply width={'152px'} height={'48px'}>
            {pageLocale['Btn title']}
          </ButtonApply> */}
        </div>
      )}
    </>
  );
};
