import React from 'react';

export const themesStorage = [
  {
    name: 'Light',
    code: 'light',
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      text: {
        accent: '#141519',
        plain: '#616161',
        lightPlain: '#BBBFCC',
        success: '#18B368',
        errorOne: '#B30000',
        errorTwo: '#E62E2E',
        errorThree: '#990000',
      },
      border: {
        active: '#241D1C',
        passive: '#E6DCDC',
        currency: '#DCDEE6',
      },
      btn: {
        bgBtn: '#241D1C',
        hover: '#00DE89',
      },
      bg: {
        main: '#FFFFFF',
        // section: '#F6F7F9',
        section: '#00000026',
        currency: '#FFFFFF',
        error: '#FFE6E6',
        opacityBg: '0.2',
      },
      spinner: {
        bg: '#000000',
      },
    },
  },
  {
    name: 'Dark',
    code: 'dark',
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      text: {
        accent: '#FFFFFF',
        plain: '#c9c9c5',
        lightPlain: '#A3A3A2',
        success: '#18B368',
        errorOne: '#B30000',
        errorTwo: '#E62E2E',
        errorThree: '#990000',
      },
      border: {
        active: '#799B41',
        passive: '#484844',
        currency: 'transparent',
      },
      btn: {
        bgBtn: '#799B41',
        hover: '#00DE89',
      },
      bg: {
        main: '#181817',
        // section: '#292927',
        section: '#FFFFFF26',
        currency: '#41413F',
        error: '#FFE6E6',
        opacityBg: '0.2',
      },
      spinner: {
        bg: '#FFFFFF',
      },
    },
  },
];

export const delayForMenu = {
  overlayInOut: 300,
  menuInOut: 300,
  delay: 200,
};

export const overlayMenu = {
  overlayIn: '0.50',
  overlayOut: '0',
};

export const delayForWalletModal = {
  overlayInOut: 300,
  modalInOut: 300,
  delay: 200,
};
