export const ALCHEMY_PROVIDER_APIKEY = 'oClAPgO6ajO72NkLpOAmxfxsH3tDY97W';
export const INFURA_PROVIDER_APIKEY = '09fb5dc11f5c4a8fb020de72d10a7b0c';
export const UNIRAMP_0X_APIKEY = '58b85550-3a8e-436f-9306-b91d918a7999';
export const ETHERSCAN_APIKEY = '7U2KHKEA4ZZHB7DV1F7Q7S1U2BRF5JIJB3';
export const BSCSCAN_APIKEY = 'SRQD2D4R6KMDG8IJEWNA2MT27DVIDAEZ1Z';
export const POLYGONSCAN_APIKEY = 'AFFI2BDHFW1QGVPTFPRVC8VCKUEG29QJIG\n';

export const UrlEtherScan =
  'https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=';
export const UrlBscScan =
  'https://api.bscscan.com/api?module=gastracker&action=gasoracle&apikey=';
export const UrlPolygonScan =
  'https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=';
export const Url0X = 'https://api.0x.org/swap/v1/';
export const ONRAMPER_KEY =
  'Basic pk_prod_jpvRjEeY4KcgkNyfPJtbmXGOCNpFUWMpLn1tMjvUFLM0';

export const UNIRAMP_HEADER_FIELD = 'x-api-key';
export const UNIRAMP_API_APIKEY = 'pk_test_C5ayW4c9DhlY7iCVu82p4g6h';
export const UNIRAMP_API_URL = 'https://api.uniramp.dev/v1/onramp/';
export const UNIRAMP_URL_PATH = {
  getFiat: 'supported/fiat',
  getCrypto: 'supported/crypto',
};
