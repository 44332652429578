import { useCallback, useEffect, useState } from 'react';
import { CloseSVG } from '../../assets/svgStore';
import { delayForMenu as delay, overlayMenu } from '../../assets/themesStorage';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../../store/uniramp/uniramp.selector';
import { unirampActions } from '../../store/uniramp/uniramp.slice';

export const DrawerComponent = ({
  isOpen,
  setIsOpen,
  position = 'down',
  menuSize = '75%',
  closeBtn = true,
  x = '24px',
  y = '16px',
  children,
}) => {
  const dispatch = useDispatch();

  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  const isClosePopup = useSelector(unirampSelectors.getIsClosePopup());
  const isClose = useSelector(unirampSelectors.getIsClose());

  const [showOverlay, setShowOverlay] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);

  const fadeIn = () => {
    setShowOverlay(true);
    setTimeout(() => {
      setShowMenu(true);
    }, delay?.delay);
    setTimeout(() => {
      setShowCloseBtn(true);
    }, delay?.delay + delay?.menuInOut);
  };

  const fadeOut = useCallback(() => {
    if (setIsOpen) {
      setShowCloseBtn(false);
      setTimeout(() => {
        setShowMenu(false);
      }, delay?.delay / 2);
      setTimeout(() => {
        setShowOverlay(false);
      }, delay?.delay + delay?.delay / 2);

      setTimeout(() => {
        setIsOpen(false);
        dispatch(unirampActions.setIsClosePopup(false));
      }, delay?.delay + delay?.delay / 2 + delay?.overlayInOut);
    }
  }, [setIsOpen]);

  useEffect(() => {
    if (isClose) {
      fadeOut();
      dispatch(unirampActions.setIsClose(null));
    }
  }, [dispatch, fadeOut, isClose]);

  useEffect(() => {
    isOpen && fadeIn(); //: fadeOut();
  }, [fadeOut, isOpen]);

  useEffect(() => {
    if (isClosePopup) {
      fadeOut();
    }
  }, [fadeOut, isClosePopup]);

  const switchPosition = () => {
    switch (position) {
      case 'down':
        return {
          width: '100%',
          left: '0',
          bottom: '0',
          height: '100%',
          maxHeight: showMenu ? menuSize : `0`,
        };
      case 'right':
        return {
          width: '100%',
          right: '0',
          top: '0',
          height: '100%',
          maxWidth: showMenu ? menuSize : `0`,
        };
      default:
        return {};
    }
  };

  return (
    <>
      <div
        className={`w-full h-full absolute bg-black top-0 left-0 z-20 rounded-lg`}
        style={{
          visibility: showOverlay ? 'visible' : 'hidden',
          opacity: showOverlay
            ? overlayMenu?.overlayIn
            : overlayMenu?.overlayOut,
          transition: `all ${String(delay?.overlayInOut)}ms`,
        }}
        onClick={() => fadeOut()}
      ></div>
      <div
        className={`absolute rounded-lg z-30`}
        style={{
          ...switchPosition(),
          backgroundColor: currentConfig?.colBg,
          transition: `all ${String(delay?.overlayInOut)}ms`,
        }}
      >
        {closeBtn && (
          <div
            className={`absolute w-4 h-4  cursor-pointer`}
            style={{
              visibility: showCloseBtn ? 'visible' : 'hidden',
              opacity: showCloseBtn ? 1 : 0,
              transition: `all ${delay?.delay / 2}ms`,
              top: x,
              right: y,
            }}
          >
            <div
              className={` w-4 h-4 cursor-pointer flex items-center justify-center`}
              onClick={() => fadeOut()}
              style={{
                color: currentTheme.text.plain,
              }}
            >
              <CloseSVG />
            </div>
          </div>
        )}
        {children}
      </div>
    </>
  );
};
