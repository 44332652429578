import { useState } from 'react';

import { BurgerSVG, LogoSVG } from '../assets/svgStore';

import { LeftMenuLinks } from './serviceComponents/leftMenuLinks';
import { DrawerComponent } from './serviceComponents/drawerComponent';

import { ChoiceWalletModal } from './modals/choiceWalletModal';
import { checkedBg } from '../services/checkedBg';
import { useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';

export default function NavigationBox() {
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenMenuSellComing, setIsOpenMenuSellComing] = useState(false);

  return (
    <>
      {currentConfig && (
        <div className={`w-full h-7 flex justify-between items-center  mb-4`}>
          <a href="https://uniramp.com" target="_blank" rel="noreferrer">
            <div
              className=" w-6 h-6"
              style={{ color: checkedBg(currentConfig?.colBg) }}
            >
              <LogoSVG />
            </div>
          </a>
          {/* не удалять - блок селл */}
          {/* <div
            className=" flex  gap-1  absolute"
            style={{ left: '50%', transform: 'translateX(-50%)' }}
          >
            <div
              onClick={() => setPageBySell('Buy')}
              className=" cursor-pointer font-bold text-base underline  decoration-solid "
              style={{ color: currentTheme?.text?.accent }}
            >
              {currentLocale['Buy']}
            </div>
            <span
              className=" text-base"
              style={{ color: currentTheme?.text?.accent }}
            >
              /
            </span>
            <div
              className=" cursor-pointer relative"
              style={{ color: currentTheme?.text?.accent }}
            >
              <div
                className=" cursor-pointer  text-base"
                // onClick={() => setPageBySell('Sell')}
                onClick={() => setIsOpenMenuSellComing(true)}
                style={{ color: currentTheme?.text?.accent }}
              >
                {currentLocale['Sell']}
              </div>
            </div>
          </div> */}
          <ChoiceWalletModal
            isOpen={isOpenMenuSellComing}
            setIsOpen={setIsOpenMenuSellComing}
          />
          <div className={`flex items-center`}>
            <div
              onClick={() => setIsOpenMenu(true)}
              style={{ color: currentTheme.text.plain }}
              className="cursor-pointer  ml-4"
            >
              <BurgerSVG />
            </div>
          </div>
          {isOpenMenu && (
            <DrawerComponent
              isOpen={isOpenMenu}
              setIsOpen={setIsOpenMenu}
              position={'right'}
              menuSize={'90%'}
            >
              <LeftMenuLinks
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={setIsOpenMenu}
              />
            </DrawerComponent>
          )}
        </div>
      )}
    </>
  );
}
