import { useState, useEffect } from 'react';
import { MenuGateways } from './menuGateways';
import { DrawerComponent } from './serviceComponents/drawerComponent';
import { Spinner } from './serviceComponents/spinner';
import { useSelector, useDispatch } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { errorState } from '../assets/errorState';
import { checkedBg } from '../services/checkedBg';
import { unirampActions } from '../store/uniramp/uniramp.slice';

export default function InfoBox() {
  const dispatch = useDispatch();

  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());

  const currentGatewayDec = useSelector(
    unirampSelectors.getCurrentGatewayDec()
  );

  const isGatewaysListStatusDec = useSelector(
    unirampSelectors.getIsCurrentGatewaysListDec()
  );
  const errorCeFiPayments = useSelector(
    unirampSelectors.getErrorCeFiPayments()
  );

  const [isOpenChangers, setIsOpenChangers] = useState(false);

  const isCurrentGatewaysListStatusDec = useSelector(
    unirampSelectors.getIsCurrentGatewaysListDec()
  );
  const currentGatewaysListDec = useSelector(
    unirampSelectors.getCurrentGatewaysListDec()
  );
  const currentFiat = useSelector(unirampSelectors.getCurrentFiat());
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  const serviceListGateways = useSelector(
    unirampSelectors.getServiceListGateways()
  );

  // const currentCurrency = typeCurrency === 'fiat' ? currentFiat : currentCrypto;
  const currentCurrency = currentCrypto;

  const [localCurrentGatewayList, setLocalCurrentGatewayList] = useState(null);
  const [localCurrentGateway, setLocalCurrentGateway] = useState(null);

  useEffect(() => {
    if (
      currentGatewaysListDec?.length &&
      isCurrentGatewaysListStatusDec === 'success'
    ) {
      const temp = [...currentGatewaysListDec];
      //console.log('temp', temp);
      const recommendedValue = Math.max(
        ...temp.map(item => item.decimalResult)
      );
      //console.log('recommendedValue', recommendedValue);
      const gatewaysWithRecommended = temp.map(item => {
        if (item.decimalResult === recommendedValue) {
          return { ...item, status: 'Recommended' };
        } else {
          return item;
        }
      });

      //console.log('gatewaysWithRecommended', gatewaysWithRecommended);
      const gatewayWithRecommended = gatewaysWithRecommended.find(
        el => el.status === 'Recommended'
      );
      //console.log('gatewayWithRecommended', gatewayWithRecommended);
      setLocalCurrentGatewayList(gatewaysWithRecommended);
      setLocalCurrentGateway(gatewayWithRecommended);
      dispatch(unirampActions.setCurrentGatewayDec(gatewayWithRecommended));
    }
  }, [currentGatewaysListDec, dispatch, isCurrentGatewaysListStatusDec]);

  return (
    <>
      <div
        className={` w-full h-11 rounded-b-lg p-[10px] cursor-pointer mb-4 flex justify-between items-center relative z-0`}
        style={{ backgroundColor: currentTheme.bg.section }}
        onClick={() => {
          isGatewaysListStatusDec === 'success' &&
            currentGatewaysListDec?.length > 1 &&
            setIsOpenChangers(true);
        }}
      >
        {isGatewaysListStatusDec === 'pending' && (
          <div className=" w-full flex items-center justify-center relative z-0 scale-50">
            <Spinner bgColor={checkedBg(currentConfig?.colBg)} />
          </div>
        )}
        {isGatewaysListStatusDec === 'failed' && (
          <p
            className=" w-full text-xs text-center"
            style={{ color: currentTheme?.text?.errorTwo }}
          >
            {errorState[`${errorCeFiPayments?.data?.type}`]
              ? `${errorState[`${errorCeFiPayments?.data?.type}`]?.message}${
                  errorCeFiPayments?.data?.amount
                    ? parseInt(errorCeFiPayments?.data?.amount) /
                      Math.pow(10, currentFiat?.decimal)
                    : ''
                }`
              : currentLocale['alertRateSettings']}
          </p>
        )}
        {isGatewaysListStatusDec === 'success' && (
          <div className=" w-[100%] flex gap-2 items-center justify-between">
            <div className=" flex items-center gap-2">
              <p
                className=" text-xs"
                style={{ color: currentTheme?.text?.accent }}
              >
                {currentLocale['By']}
              </p>
              <img
                src={currentGatewayDec?.image}
                alt={currentGatewayDec?.name}
                className="w-5 h-5 rounded-full p-[1px] bg-white"
              />
              <p
                className=" text-xs"
                style={{ color: currentTheme?.text?.accent }}
              >
                {currentGatewayDec?.name}
                <span>
                  {currentGatewayDec?.type === 'direct'
                    ? ''
                    : currentLocale['uniramp protocol']}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
      {isOpenChangers && (
        <DrawerComponent
          isOpen={isOpenChangers}
          setIsOpen={setIsOpenChangers}
          position={'down'}
          menuSize={'90%'}
        >
          <MenuGateways
            setIsOpen={setIsOpenChangers}
            localCurrentGatewayList={localCurrentGatewayList}
            setLocalCurrentGateway={setLocalCurrentGateway}
            localCurrentGateway={localCurrentGateway}
            setLocalCurrentGatewayList={setLocalCurrentGatewayList}
          />
        </DrawerComponent>
      )}
    </>
  );
}
