import { useAppContext } from '../contextWrapper';
import { v4 as uuid } from 'uuid';

import React from 'react';

import {
  Failed,
  LinkHistorySVG,
  MoonpaySVG,
  OxSVG,
  PendingSVG,
  SuccessSVG,
  TransakSVG,
} from '../assets/svgStore';

export default function Transaction() {
  const { transactionsList, currentTheme, currentLocale } = useAppContext();

  const takeDate = (milliseconds) => {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const formattedDate = `${year}.${month}.${day}, ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const changerArr = {
    Transak: <TransakSVG />,
    '0x.org': <OxSVG />,
    Moonpay: <MoonpaySVG />,
  };

  const statusArr = {
    pending: <PendingSVG />,
    completed: <SuccessSVG />,
    failed: <Failed />,
    new: <></>,
  };

  const filteredStatus = ['completed', 'failed'];

  return (
    <div>
      {transactionsList &&
        transactionsList?.map((transactions) => (
          <div
            key={uuid()}
            className="w-[100%] rounded-lg text-cyan-600 px-1 py-2 "
            style={{ backgroundColor: currentTheme.bg.section }}
          >
            {/* {transactions.transactions &&
              transactions.transactions.length > 1 && (
                <div className="relative">
                  {transactions.transactions
                    ?.filter((el) =>
                      filteredStatus.includes(el?.body?.type.split('_')[1])
                    )
                    ?.map((transaction, index) => (
                      <React.Fragment key={uuid()}>
                        {index > 0 && (
                          <div
                            className={` absolute line-${index}`}
                            style={{
                              backgroundColor: currentTheme.bg.main,
                              height: '6rem',
                            }}
                          ></div>
                        )}
                        <div></div>
                      </React.Fragment>
                    ))}
                </div>
              )} */}
            {transactions.transactions &&
              transactions.transactions
                ?.filter((el) =>
                  filteredStatus.includes(el?.body?.type.split('_')[1])
                )
                ?.map((el, index) => (
                  <div key={uuid()} className=" relative">
                    {index !==
                      transactions.transactions?.filter((el) =>
                        filteredStatus.includes(el?.body?.type.split('_')[1])
                      ).length -
                        1 && (
                      <div
                        className=" absolute w-[3px] h-[94px] left-[14px] top-[32px]"
                        style={{
                          backgroundColor: currentTheme.bg.main,
                          height: '6rem',
                        }}
                      ></div>
                    )}
                    <div className="flex mt-4 items-center">
                      <div
                        className="rounded-full w-8 h-8 mr-2 flex justify-center items-center "
                        style={{ backgroundColor: currentTheme.bg.main }}
                      >
                        <div className="w-4 h-4 ">
                          {changerArr[el.body.payload.gatewayIdentifier]}
                        </div>
                      </div>

                      <div
                        className="text-md font-bold whitespace-nowrap text-ellipsis text-base overflow-hidden"
                        style={{ color: currentTheme?.text?.accent }}
                      >
                        {el.body.payload.gatewayIdentifier}
                      </div>
                      <div className="ml-auto w-fit">
                        <div
                          className=" px-1 py-1 pr-2 flex justify-between items-center gap-1 text-xs rounded-full"
                          style={{
                            color: currentTheme.text.plain,
                            backgroundColor: currentTheme.bg.main,
                          }}
                        >
                          <div className="w-4 h-4 flex justify-center items-center">
                            {statusArr[el.body.type.split('_')[1]]}
                          </div>
                          <div className="w-fit h-4 flex justify-center text-xs items-center">
                            {el.body.type.split('_')[1]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="text-xs flex ml-10 mb-1"
                      style={{ color: currentTheme.text.plain }}
                    >
                      {currentLocale?.Spend}
                      <div
                        className="ml-4 text-xs font-bold"
                        style={{ color: currentTheme?.text?.accent }}
                      >
                        {el.body.payload.inAmount}
                        {el.body.payload.inCurrency}
                      </div>
                    </div>

                    <div
                      className="text-xs flex  ml-10  mb-1"
                      style={{ color: currentTheme.text.plain }}
                    >
                      {currentLocale?.Receive}
                      <div
                        className="ml-2 text-xs font-bold"
                        style={{ color: currentTheme?.text?.accent }}
                      >
                        {el.body.payload.outAmount}
                        {el.body.payload.outCurrency}
                      </div>
                    </div>

                    <div
                      className="text-xs flex  ml-10  mb-1"
                      style={{ color: currentTheme.text.plain }}
                    >
                      {currentLocale?.Date}
                      <div
                        className="ml-6 text-xs font-bold"
                        style={{ color: currentTheme?.text?.accent }}
                      >
                        {takeDate(el.body.payload.timestamp)}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div
                        style={{
                          color: currentTheme.text.plain,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: currentTheme.bg.main,
                          width: '130px',
                          fontSize: '12px',
                        }}
                        className=" ml-10 rounded-full "
                      >
                        <a
                          href="https://etherscan.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mr-1 "
                        >
                          {currentLocale?.ViewInEtherscan}
                        </a>
                        <div className=" w-[12px] h-3">
                          <LinkHistorySVG />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        ))}
    </div>
  );
}
