import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { Buffer } from 'buffer';
import { Provider } from 'react-redux';
import store from './widget/store';

// @js-ignore
window.Buffer = Buffer;

const WidgetDivs = document.querySelectorAll('.uniramp');

WidgetDivs.forEach((Div) => {
  ReactDOM.createRoot(Div).render(
    <>
      <Provider store={store}>
        <App domElement={Div} />
      </Provider>
    </>
  );
});
