import React, { useEffect, useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/night-owl.css';
import xml from 'highlight.js/lib/languages/xml';
import { linkToCss, linkToJs } from '../../assets/links';
import { usePageContext } from '../../pageConfig';
import { colorPage } from '../../assets/colorSet';
import { useSelector } from 'react-redux';
import unirampSelectors from '../../../widget/store/uniramp/uniramp.selector';
hljs.registerLanguage('xml', xml);

export const Installation = (props) => {
  const { pageLocale } = usePageContext();
  const { config } = props;
  const [isOpenCss, setIsOpenCss] = React.useState(false);
  const [isOpenDiv, setIsOpenDiv] = React.useState(false);
  const [isOpenScript, setIsOpenScript] = React.useState(false);
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const apiKeyConfigPage = useSelector(unirampSelectors.getApiKeyConfigPage());

  React.useEffect(() => {
    hljs.highlightAll();
  }, [config]);

  const onCopyClick = (text, setIsCopy) => {
    if (document.queryCommandSupported('copy')) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      setIsCopy(true);
      document.body.removeChild(textArea);
    } else {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setIsCopy(true);
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error);
        });
    }
    setTimeout(() => {
      setIsCopy(false);
    }, 1000);
  };

  const [stringState, setStringState] = useState('');

  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  useEffect(() => {
    setStringState(
      `<iframe title='uniramp' src='https://staging.uniramp.com/widget.html?theme=${
        config?.theme
      }&colBg=${config?.colBg.replace(
        /#/g,
        ''
      )}&colBtn=${config?.colBtn.replace(/#/g, '')}&token=${
        currentCrypto?.address || currentCrypto?.ticker
      }&chain=${currentCrypto?.chain}&apiKey=${apiKeyConfigPage}        
      ' style='width: 372px; height: 518px; border: none; border-radius: 8px;'></iframe>`
    );
  }, [
    apiKeyConfigPage,
    config?.colBg,
    config?.colBtn,
    config?.theme,
    currentCrypto,
  ]);

  // &ticker=${
  //         currentCrypto?.ticker || ''
  //       }&chain=${
  //         currentCrypto?.chain || ''
  //       }

  return (
    <div className={`flex flex-col gap-4 relative`}>
      {/* <h3 className={`uppercase font-bold text-[${colorPage.title}] my-6`}>
        {pageLocale['Installation']}
        <span className={` lowercase`}>{` as <div>`}</span>
      </h3>

      <div className="w-[100%]">
        <div className="w-full rounded-2xl overflow-hidden overflow-x-auto">
          <pre>
            <code className="language-xml">
              {`<!-- Step 1: copy this line to the <header> section -->`}
            </code>
            <div className="w-[100%] bg-[#011627]">
              <button
                className=" w-20 h-7 border border-gray-600 rounded-lg bg-slate-100  ml-3"
                onClick={() => {
                  onCopyClick(
                    `<link href=${linkToCss} rel="stylesheet" />`,
                    setIsOpenCss
                  );
                }}
              >
                {isOpenCss ? 'Copied !' : 'Copy'}{' '}
              </button>
            </div>
            <code className="language-xml max-w-[100%] break-all">
              {`<link href="https://widget.uniramp.com/widget.css" rel="stylesheet"/>`}
            </code>

            <code>
              {`<!-- Step 2: copy this line to the desired location in the code in the <body> section -->`}
            </code>
            <div className="w-[100%] bg-[#011627]">
              <button
                className=" w-20 h-7 border border-gray-600 rounded-lg bg-slate-100 ml-3"
                onClick={() => {
                  onCopyClick(
                    `<div class="uniramp" data-config="theme:${config.theme}, colBg:${config.colBg}, colBtn:${config.colBtn}, initAmountFiat:${config.initAmountFiat}, initFiat:${config.initFiat}, initCrypto:${config.initCrypto}, initPayment:${config.initPayment}, lang:${config.lang}"></div>`,
                    setIsOpenDiv
                  );
                }}
              >
                {isOpenDiv ? 'Copied !' : 'Copy'}{' '}
              </button>
            </div>
            <code className="language-xml">{`  <div class="uniramp"
    data-config="
    theme: ${config.theme}, 
    colBg: ${config.colBg.toUpperCase()}, 
    colBtn: ${config.colBtn.toUpperCase()},
    initAmountFiat: ${config.initAmountFiat},
    initFiat: ${config.initFiat},
    initCrypto: ${config.initCrypto},
    initPayment: ${config.initPayment},
    lang: ${config.lang}
  "                
  >      
  </div>`}</code>

            <code>{`<!-- Step 3: copy this line before the end of the <body> section -->`}</code>
            <div className="w-[100%] bg-[#011627]">
              <button
                className="  w-20 h-7 border border-gray-600 rounded-lg bg-slate-100 ml-3"
                onClick={() => {
                  onCopyClick(
                    `<script src=${linkToJs}></script>`,
                    setIsOpenScript
                  );
                }}
              >
                {isOpenScript ? 'Copied !' : 'Copy'}{' '}
              </button>
            </div>
            <code className="language-xml max-w-[100%] break-all">
              {`<script src="https://widget.uniramp.com/widget.js"></script>`}
            </code>
          </pre>
        </div>
      </div> */}
      <h3 className={`uppercase font-bold text-[${colorPage.title}] my-6`}>
        {pageLocale['Installation']}
        {/* <span className={` lowercase`}>{` as <iframe>`}</span> */}
      </h3>
      <pre>
        <div className="w-full rounded-2xl overflow-hidden overflow-x-auto">
          <div className="w-[100%] bg-[#011627] py-4">
            <button
              className=" w-20 h-7 border border-gray-600 rounded-lg bg-slate-100 ml-3"
              onClick={() => {
                onCopyClick(stringState, setIsOpenDiv);
              }}
            >
              {isOpenDiv ? 'Copied !' : 'Copy'}{' '}
            </button>
            <div className=" p-4">
              <span className=" text-[#7fdbca] break-all">
                &lt;iframe title=
              </span>
              <span className=" text-[#ecc48d] break-all">'uniramp'</span>
              <span className=" text-[#7fdbca] break-all"> src=</span>
              <span className=" text-[#ecc48d] break-all">
                {`'https://staging.uniramp.com/widget.html?theme=${
                  config?.theme
                }&colBg=${config?.colBg.replace(
                  /#/g,
                  ''
                )}&colBtn=${config?.colBtn.replace(/#/g, '')}&token=${
                  currentCrypto?.address || currentCrypto?.ticker
                }&chain=${currentCrypto?.chain}&apiKey=${apiKeyConfigPage}'`}
              </span>
              <span className=" text-[#7fdbca] break-all"> style=</span>
              <span className=" text-[#ecc48d] break-all">
                'width: 372px; height: 518px; border: none; border-radius: 8px;'
              </span>
              <span className=" text-[#7fdbca] break-all">
                &gt;&lt;/iframe&gt;
              </span>
            </div>
            {/* <code className="language-xml break-all">{stringState}</code> */}
          </div>
        </div>
      </pre>
    </div>
  );
};

// 141
//                   &initAmountFiat=${config?.initAmountFiat}&initFiat=${
//                     config?.initFiat
//                   }
//                   &initCrypto=${config?.initCrypto}&initPayment=${
//                     config?.initPayment
//                   }
//                   &lang=${config?.lang}

// 155
//               &initAmountFiat=${config?.initAmountFiat}&initFiat=${
//                 config?.initFiat
//               }
//               &initCrypto=${config?.initCrypto}&initPayment=${
//                 config?.initPayment
//               }
//               &lang=${config?.lang}
