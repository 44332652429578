import React from 'react';
import { colorPage } from './assets/colorSet';
import { Configuration } from './components/configuration/configuration';
import { Installation } from './components/installation/installation';
import { ContextPageConfig } from './contexts/contextPageConfig';
import en from './locale/en.json';

export const PageConfig = (props) => {
  const [pageLocale, setPageLocale] = React.useState(null);
  const [isOpenMenu, seIsOpenMenu] = React.useState({
    theme: false,
    colBg: false,
    colBtn: false,
    token: null,
    // initAmountFiat: false,
    // initFiat: false,
    // initCrypto: false,
    // initPayment: false,
    // lang: false,
  });
  React.useEffect(() => {
    setPageLocale(en);
  }, []);
  return (
    <>
      <ContextPageConfig.Provider
        value={{ pageLocale, setPageLocale, isOpenMenu, seIsOpenMenu }}
      >
        {pageLocale && (
          <div
            className={` w-[100%] max-w-[1024px] grid gap-8 p-5 mx-auto rounded-lg shadow-lg`}
            style={{ backgroundColor: colorPage?.bgColorComponent }}
          >
            <Configuration config={props.config} setConfig={props.setConfig} />
            <Installation config={props.config} />
          </div>
        )}
      </ContextPageConfig.Provider>
    </>
  );
};

export const usePageContext = () => {
  const context = React.useContext(ContextPageConfig);
  return context;
};
