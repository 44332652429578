import { useEffect, useState } from 'react';
import './app.css';
import { PageConfig } from './pageconfig/pageConfig';
import { defaultConfigStore } from './widget/assets/storage';
import WidgetApp from './widget/widgetApp';
import { colorPage } from './pageconfig/assets/colorSet';

function App({ domElement }) {
  const [config, setConfig] = useState(defaultConfigStore);
  const w3web = document.querySelectorAll('w3m-modal');

  useEffect(() => {
    if (w3web.length > 1) {
      for (let i = 1; i < w3web.length; i++) {
        w3web[i].remove();
      }
    }
  }, [w3web]);

  return (
    <>
      {domElement?.id &&
      domElement?.id === 'uniramp' &&
      window.location.search === '' ? (
        <>
          <div
            className={`w-[100%] h-[100%] flex justify-content items-center`}
            style={{ backgroundColor: 'transparent' }}
          >
            <div className={` base:block lg:flex gap-10 mx-auto`}>
              <div
                className=" rounded-lg"
                style={{ backgroundColor: colorPage?.bgColorMain }}
              >
                <PageConfig config={config} setConfig={setConfig} />
              </div>
              <div className="w-[100%] max-w-[364px] h-[540px] rounded-lg base:mx-auto base:mt-10 lg:mx-0 lg:mt-0">
                <WidgetApp config={config} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <WidgetApp domElement={domElement} />
      )}
    </>
  );
}

export default App;
