import {
  createApi,
  fakeBaseQuery,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

export const unirampApi = createApi({
  reducerPath: 'unirampApi',
  tagTypes: [],
  endpoints: (builder) => ({}),
});
