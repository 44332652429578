import { useDispatch, useSelector } from 'react-redux';
import {
  useLazyGetCeFiOnramperQuery,
  useLazyGetDiFiQuoteQuery,
} from '../store/uniramp/uniramp.api';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { useCallback, useEffect } from 'react';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import { divideByPowerOfTen } from '../services/convertedByBigNumber';

export const useGetCiFiOnramper = () => {
  const dispatch = useDispatch();
  const [getCeFiOnramper] = useLazyGetCeFiOnramperQuery();
  const [getDiFiQuote] = useLazyGetDiFiQuoteQuery();

  const currentFiat = useSelector(unirampSelectors.getCurrentFiat());
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  const currentAmount = useSelector(unirampSelectors.getCurrentAmount());
  const currentPayment = useSelector(unirampSelectors.getCurrentPayment());

  const isGatewaysListStatus = useSelector(
    unirampSelectors.isGatewaysListStatus()
  );
  const getGatewaysList = useSelector(unirampSelectors.getGatewaysList());

  useEffect(() => {
    dispatch(unirampActions.rejectCurrentGateways());
  }, [currentAmount, currentCrypto, currentFiat, currentPayment, dispatch]);

  const memoGetCeFiOnramper = useCallback(async () => {
    if (
      currentFiat?.id &&
      currentCrypto?.ticker &&
      currentAmount &&
      currentPayment?.id
    ) {
      let params = {
        fiat: currentFiat?.id,
        fiatAmount: currentAmount * Math.pow(10, currentFiat?.decimal),
        payment: currentPayment?.id,
        chain: currentCrypto?.chain,
      };
      if (currentCrypto?.address) {
        params = { ...params, crypto: currentCrypto?.address };
      }
      try {
        await getCeFiOnramper(params);
      } catch (error) {
        console.log('error', error);
      }
    }
  }, [
    currentAmount,
    currentCrypto,
    currentFiat,
    currentPayment,
    getCeFiOnramper,
  ]);

  useEffect(() => {
    dispatch(unirampActions.rejectCurrentGateways());
    if (
      currentFiat?.id &&
      currentCrypto?.ticker &&
      currentAmount &&
      currentPayment?.id
    ) {
      memoGetCeFiOnramper();
    }
  }, [
    currentAmount,
    currentCrypto,
    currentFiat,
    currentPayment,
    dispatch,
    memoGetCeFiOnramper,
  ]);

  const memoNewListGateways = useCallback(async () => {
    try {
      if (getGatewaysList && getGatewaysList.length) {
        for (let index = 0; index < getGatewaysList.length; index++) {
          const gateway = getGatewaysList[index];
          if (gateway?.type === 'hybrid') {
            const params = {
              chain: currentCrypto?.chain,
              crypto: currentCrypto?.address,
              amount: gateway?.cryptoAmount,
            };
            try {
              const { data } = await getDiFiQuote(params);
              const resTemp = [...data?.gateways];
              // resTemp?.sort(
              //   (a, b) =>
              //     divideByPowerOfTen(b?.toAmount, data?.cryptoDecimal) -
              //     divideByPowerOfTen(a?.toAmount, data?.cryptoDecimal)
              // );
              const temp = {
                ...gateway,
                decimalResult: divideByPowerOfTen(
                  resTemp[0]?.toAmount,
                  data?.cryptoDecimal
                ),
                gatewayDiFi: resTemp[0]?.gateway,
              };
              dispatch(unirampActions.setCurrentGatewaysListDec(temp));
              if (index === getGatewaysList.length - 1) {
                dispatch(unirampActions.setIsGatewaysListStatusDec('success'));
              }
            } catch (error) {
              // Handle error if required
            }
          } else {
            const temp = {
              ...gateway,
              decimalResult: divideByPowerOfTen(
                gateway?.cryptoAmount,
                gateway?.cryptoDecimal
              ),
            };
            dispatch(unirampActions.setCurrentGatewaysListDec(temp));
            if (index === getGatewaysList.length - 1) {
              dispatch(unirampActions.setIsGatewaysListStatusDec('success'));
            }
          }
        }
      }
    } catch (error) {
      console.log('error', error);
      dispatch(unirampActions.setIsGatewaysListStatusDec('failed'));
    }
  }, [dispatch, getDiFiQuote, getGatewaysList]); //list of conditions not rewritten

  useEffect(() => {
    if (getGatewaysList?.length && isGatewaysListStatus === 'success') {
      memoNewListGateways();
    }
  }, [getGatewaysList, isGatewaysListStatus, memoNewListGateways]);
};
