import React, { useEffect } from 'react';
import { MenuPaymentMethods } from './menuPaymentMethods';
import { DrawerComponent } from './serviceComponents/drawerComponent';
import { Spinner } from './serviceComponents/spinner';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import { ThreeDotsSVG } from '../assets/svgStore';
import { checkedBg } from '../services/checkedBg';

const menuPaymentObj = {
  id: 'menu',
  name: 'Menu',
  image: <ThreeDotsSVG />,
};

export default function ChoicePaymentBox() {
  const dispatch = useDispatch();
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  const currentPayment = useSelector(unirampSelectors.getCurrentPayment());
  const currentPaymentList = useSelector(unirampSelectors.getPaymentList());

  const [isOpenPayMentMenu, setIsOpenPayMentMenu] = React.useState(false);
  const [buttonList, setButtonList] = React.useState(null);

  useEffect(() => {
    if (currentPaymentList?.length) {
      if (currentPaymentList?.length > 3) {
        setButtonList([...currentPaymentList?.slice(0, 3), menuPaymentObj]);
      } else setButtonList([...currentPaymentList]);
    }
  }, [currentPaymentList]);

  useEffect(() => {
    if (currentPayment) {
      if (
        currentPaymentList?.findIndex((item) => item.id === currentPayment.id) >
        2
      ) {
        setButtonList([
          currentPayment,
          ...currentPaymentList?.slice(0, 2),
          menuPaymentObj,
        ]);
      } else {
        if (currentPaymentList?.length > 3) {
          setButtonList([...currentPaymentList?.slice(0, 3), menuPaymentObj]);
        } else setButtonList([...currentPaymentList]);
      }
    }
  }, [currentPayment, currentPaymentList]);

  return (
    <>
      <div className={`w-full h-16 flex gap-4 rounded-lg mb-2`}>
        {!buttonList?.length ? (
          <>
            <div className=" w-[100%] flex items-center justify-center">
              <Spinner bgColor={checkedBg(currentConfig?.colBg)} />
            </div>
          </>
        ) : (
          <>
            {buttonList?.map((item, index) => {
              return (
                <div
                  key={'choicePaymentBox' + index}
                  className={` w-[100px] h-12 border-[2px] cursor-pointer rounded-lg flex flex-col justify-center items-center `}
                  style={{
                    borderColor:
                      item?.id === currentPayment?.id
                        ? currentConfig?.colBtn
                        : currentTheme.border.passive,
                    backgroundColor:
                      item?.id === currentPayment?.id ? '#FFFFFF' : '#FFFFFF',
                  }}
                  onClick={() => {
                    if (item?.id === 'menu') {
                      setIsOpenPayMentMenu(true);
                    } else {
                      dispatch(unirampActions.setCurrentPayment(item));
                    }
                  }}
                >
                  <div
                    className=" w-14 h-14 flex justify-center items-center overflow-hidden"
                    style={{ color: currentTheme.text.accent }}
                  >
                    {item?.id !== 'menu' ? (
                      <img src={item?.image} alt={item?.name} />
                    ) : (
                      <div className=" text-black">{item?.image}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {isOpenPayMentMenu && (
        <DrawerComponent
          isOpen={isOpenPayMentMenu}
          setIsOpen={setIsOpenPayMentMenu}
          position={'down'}
          menuSize={'90%'}
        >
          <MenuPaymentMethods setIsOpen={setIsOpenPayMentMenu} />
        </DrawerComponent>
      )}
    </>
  );
}
