import React from 'react';
import { useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { sizeForPopup } from '../assets/sizeForPopup';

export const ContinuePayment = ({ isOpen }) => {
  const transactionResponse = useSelector(
    unirampSelectors.getSendTransactionResponse()
  );

  return (
    <>
      {transactionResponse && sizeForPopup && (
        <div
          className="pt-8 rounded-lg overflow-hidden"
          style={{
            boxShadow: '0 0 6px rgba(0, 0, 0, 0.3)',
            width: sizeForPopup?.buyMenu?.width,
            height: sizeForPopup?.buyMenu?.height,
          }}
        >
          <div className="w-[100%] h-[438px]  overflow-hidden   border-t-2 ">
            <iframe
              title="changer"
              src={transactionResponse?.cefiInitiate?.url}
              className="w-[100%] h-[100%] "
              sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-presentation allow-same-origin allow-scripts"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};
