import { useEffect, useState } from 'react';
import { checkedBg } from '../services/checkedBg';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import { CheckMarkSVG } from '../assets/svgStore';
import { sizeForPopup } from '../assets/sizeForPopup';

export const MenuPaymentMethods = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());

  const currentPaymentList = useSelector(unirampSelectors.getPaymentList());
  const currentPayment = useSelector(unirampSelectors.getCurrentPayment());

  const [localPaymentList, setLocalPaymentList] = useState(null);

  useEffect(() => {
    if (currentPaymentList && currentPaymentList?.length > 3) {
      if (
        currentPaymentList?.findIndex((el) => el.id === currentPayment?.id) <= 2
      ) {
        // setLocalPaymentList([...currentPaymentList.slice(3)]);
        setLocalPaymentList([...currentPaymentList]);
      } else {
        setLocalPaymentList([
          ...currentPaymentList,
          // ?.filter((el) => el?.id !== currentPayment?.id)
          // .slice(2),
        ]);
      }
    }
  }, [currentPayment?.id, currentPaymentList]);

  return (
    <>
      {localPaymentList && (
        <div
          className={` h-[485px] flex flex-col gap-5 pt-5 pb-12`}
          style={{
            width: sizeForPopup?.currencyMenu?.width,
            height: sizeForPopup?.currencyMenu?.height,
          }}
        >
          <h3
            className={`mx-auto text-base`}
            style={{ color: checkedBg(currentConfig?.colBg) }}
          >
            {currentLocale['Select Payment Method']}
          </h3>

          <div className={` h-[100%] overflow-auto flex flex-col gap-3 mx-4`}>
            {localPaymentList
              .filter((el) => el?.code !== 'menu')
              .map((method, index) => {
                return (
                  <div
                    className={`flex justify-between item-center transition-all cursor-pointer p-2 rounded-lg`}
                    onClick={() => {
                      dispatch(unirampActions.setCurrentPayment(method));
                      dispatch(unirampActions.setIsClosePopup(true));
                      // setIsOpen(false);
                    }}
                    key={index}
                    style={{ color: currentTheme.text.accent }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        checkedBg(currentConfig.colBg) + '26')
                    }
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                  >
                    <div className="flex w-full items-center justify-between relative">
                      <div className="flex gap-3 justify-start items-center ">
                        <div
                          className="h-12 flex justify-start items-center border border-gray-300 rounded-full"
                          style={{
                            backgroundColor: '#FFFFFF',
                          }}
                        >
                          <img
                            src={method?.image}
                            alt={method?.name}
                            className=" w-12 h-12 flex justify-start items-center border border-gray-300 rounded-full"
                          />
                        </div>
                        <div
                          className=" h-8 flex justify-center items-center text-base"
                          style={{ color: checkedBg(currentConfig?.colBg) }}
                        >
                          <p>{method?.name}</p>
                        </div>
                      </div>
                      {method?.id === currentPayment?.id && (
                        <div
                          className="w-5 h-4 ml-3"
                          style={{
                            color: checkedBg(currentConfig?.colBg),
                          }}
                        >
                          <CheckMarkSVG />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};
