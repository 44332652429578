import { useAppContext } from '../contextWrapper';
import {
  CircularArrowSVG,
  WarningSVG,
  CompletedSVG,
  DefiSwapSVG,
  SuccessSVG,
  DefiRetrySVG,
  QuestionSVG,
  SuccessNewSVG,
  CloseSVG,
} from '../assets/svgStore';
import { v4 as uuid } from 'uuid';
import { checkedBg } from '../services/checkedBg';
import { useEffect, useState } from 'react';
import { useChainModal } from '@rainbow-me/rainbowkit';
import { SpinnerRound } from './serviceComponents/spinnerRound';
import axios from 'axios';
import { questionLinks } from '../assets/questionLinks';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import {
  conditionForTransactionStatusElement,
  transactionStatus,
} from '../assets/transactionsStatus';
import { useNetwork, useSigner, useSwitchNetwork } from 'wagmi';
import Web3 from 'web3';
import {
  useGetDataFromDiFiQuery,
  useLazyGetDataFromDiFiQuery,
} from '../store/uniramp/uniramp.api';
import { send } from 'process';
import { sendTransactionToFb } from '../services/sendTransactionToFb';
import { Spinner } from './serviceComponents/spinner';

const slippageArrDefault = [
  { index: 0, value: 0.001 },
  { index: 1, value: 0.005 },
  { index: 2, value: 0.01 },
  { index: 3, value: '' },
];

export const TransactionState = ({}) => {
  const [slippageArr, setSlippageArr] = useState(null);
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  useEffect(() => {
    if (!currentCrypto?.slippage) {
      setSlippageArr(slippageArrDefault);
    } else {
      const temp = [
        { index: 0, value: currentCrypto?.slippage },
        { index: 1, value: currentCrypto?.slippage + 0.005 },
        { index: 2, value: currentCrypto?.slippage * 0.01 },
        { index: 3, value: '' },
      ];
      setSlippageArr(temp);
    }
  }, [currentCrypto?.slippage]);

  const [slippage, setSlippage] = useState(null);
  const [spinnerState, setSpinnerState] = useState(false);

  //---------------------------------------------------
  const {
    queryMarkSvg,
    closeBtnSvg,
    spinnerRound,
    successSvg,
    failedSvg,
    closeBtnCiFi,
    closeBtnCiFiSuccess,
    typeHybrid,
    typeDirect,
    defiFailed,
    successDeFi,
    helpLink,
    textShowed,
  } = conditionForTransactionStatusElement;

  const dispatch = useDispatch();
  const [getDataFromDiFi] = useLazyGetDataFromDiFiQuery();

  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());

  const isOpenTransactionState = useSelector(
    unirampSelectors.getIsOpenTransactionState()
  );
  const currentTransaction = useSelector(
    unirampSelectors.getCurrentTransactionFb()
  );
  const transactionResponse = useSelector(
    unirampSelectors.getSendTransactionResponse()
  );

  const currentGateway = useSelector(unirampSelectors.getCurrentGatewayDec());

  const [statusTransaction, setStatusTransaction] = useState(null);

  useEffect(() => {
    if (currentTransaction && currentTransaction?.data?.status) {
      setStatusTransaction(currentTransaction?.data?.status);
    }
  }, [currentTransaction, currentTransaction?.data?.status]);

  useEffect(() => {
    if (statusTransaction === 'failed_defi') {
      setSlippage(slippageArr[2]);
    }
    if (statusTransaction === 'success') {
      setSlippage(null);
    }
  }, [slippageArr, statusTransaction]);

  const [typeChanged, setTypeChanged] = useState(null);
  useEffect(() => {
    if (currentGateway && currentGateway?.type) {
      setTypeChanged(currentGateway?.type);
    }
  }, [currentGateway]);

  const onCLickQuestion = () => {
    const linkUrl = helpLink.includes(statusTransaction)
      ? statusTransaction
      : successSvg.includes(statusTransaction) &&
        typeHybrid.includes(typeChanged)
      ? 'defiSwap'
      : '';
    if (linkUrl) {
      const link = document.createElement('a');
      link.href = questionLinks[linkUrl];
      link.target = '_blank';
      link.click();
      link.remove();
    }
  };

  const onCLickClose = () => {
    dispatch(unirampActions.rejectTransaction());
    dispatch(unirampActions.setIsClose('close'));
  };

  const onClickCloseBtn = () => {
    dispatch(unirampActions.rejectTransaction());
    dispatch(unirampActions.setIsClose('close'));
  };

  const { data: signer } = useSigner();

  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  // console.log('chain', chain);
  // console.log('chain', chain);
  const changeChain = async (currentChain) => {
    // console.log('currentChain', currentChain);
    // console.log('chain.id', chain?.id);
    if (chain.id !== currentChain) {
      await switchNetworkAsync(currentChain);
    }
  };

  useEffect(() => {
    setSpinnerState(false);
  }, [statusTransaction]);

  const onClickContinueBtn = async () => {
    setSpinnerState(true);
    try {
      sendTransactionToFb({
        prevTransaction: currentTransaction,
        data: { gateway: currentGateway?.id, status: 'processing_defi' },
      });
      const { data } = await getDataFromDiFi(
        !slippage
          ? transactionResponse?.defiInitiate?.url
          : transactionResponse?.defiInitiate?.url +
              `?slippage=${String(slippage?.value)}`
      );
      // console.log('response Defi', data);
      await changeChain(data?.transaction?.chainId);

      const web3 = new Web3(signer?.provider?.provider);
      const tx = await web3.eth
        .sendTransaction({ ...data?.transaction })
        .then((data) => console.log('then tx', data));
      // console.log('tx', tx);

      if (tx?.error) {
        sendTransactionToFb({
          prevTransaction: currentTransaction,
          data: { gateway: currentGateway?.id, status: 'failed_defi' },
        });
      } else {
        sendTransactionToFb({
          prevTransaction: currentTransaction,
          data: { gateway: currentGateway?.id, status: 'success_defi' },
        });
      }
    } catch (error) {
      sendTransactionToFb({
        prevTransaction: currentTransaction,
        data: { gateway: currentGateway?.id, status: 'failed_defi' },
      });
      console.log('error', error);
    }
  };

  const startChecker = () => {
    if (
      currentTransaction &&
      isOpenTransactionState &&
      currentConfig &&
      transactionStatus &&
      statusTransaction
    ) {
      return true;
    } else {
      return false;
    }
  };

  const textChoicer = () => {
    if (
      textShowed.includes(statusTransaction)
      //   ||
      //   (closeBtnCiFiSuccess.includes(statusTransaction) &&
      //     typeDirect.includes(typeChanged))
    ) {
      return {
        header: transactionStatus[statusTransaction][0],
        text: transactionStatus[statusTransaction][1],
      };
    }
    // if (
    //   closeBtnCiFiSuccess.includes(statusTransaction) &&
    //   typeHybrid.includes(typeChanged)
    // ) {
    //   return {
    //     header: transactionStatus['defiSwap'][0],
    //     text: transactionStatus['defiSwap'][1],
    //   };
    // }
    if (
      successSvg.includes(statusTransaction) &&
      typeHybrid.includes(typeChanged)
    ) {
      return {
        header: transactionStatus['defiSwap'][0],
        text: transactionStatus['defiSwap'][1],
      };
    }
  };

  const onClickTryAgain = () => {
    onClickContinueBtn();
  };

  //---------------------------------------------------
  return (
    <>
      {startChecker() && (
        <div className="w-full h-full px-3 py-3 flex flex-col justify-between relative">
          {/* topLine */}
          <div
            className=" flex items-center gap-3 absolute top-3 right-3 cursor-pointer z-50"
            style={{ color: checkedBg(currentConfig?.colBg) }}
          >
            {queryMarkSvg.includes(statusTransaction) && (
              <QuerySvgComponent onClickHandler={onCLickQuestion} />
            )}
            {successSvg.includes(statusTransaction) &&
              typeHybrid.includes(typeChanged) && (
                <QuerySvgComponent onClickHandler={onCLickQuestion} />
              )}
            {closeBtnSvg.includes(statusTransaction) && (
              <CloseSvgComponent onClickHandler={onCLickClose} />
            )}
            {successSvg.includes(statusTransaction) &&
              typeDirect.includes(typeChanged) && (
                <CloseSvgComponent onClickHandler={onCLickClose} />
              )}
          </div>
          {/* block svg */}
          <div className=" h-[40%] flex justify-center items-center">
            <div
              className=" w-[80px] h-[80px] flex justify-center items-center"
              style={{ color: checkedBg(currentConfig?.colBg) }}
            >
              {spinnerRound.includes(statusTransaction) && <SpinnerRound />}
              {successSvg.includes(statusTransaction) &&
                typeDirect.includes(typeChanged) && <SuccessNewSVG />}
              {failedSvg.includes(statusTransaction) && <DefiRetrySVG />}
              {successSvg.includes(statusTransaction) &&
                typeHybrid.includes(typeChanged) && <DefiSwapSVG />}
              {successDeFi.includes(statusTransaction) && <SuccessSVG />}
            </div>
          </div>
          {/* block text */}
          <div className=" h-[40%] flex flex-col items-center " style={{}}>
            <HeaderBlockText
              text={textChoicer()?.header}
              currentConfig={currentConfig}
            />
            {transactionStatus[statusTransaction][1] && (
              <PlainTextBlockText
                text={textChoicer()?.text}
                currentConfig={currentConfig}
                statusTransaction={statusTransaction}
              />
            )}
            {defiFailed.includes(statusTransaction) && (
              <div
                className=" flex mt-3 text-left mb-2 items-center gap-3 w-[90%]"
                style={{ color: checkedBg(currentConfig?.colBg) }}
              >
                <p
                  className=" text-base font-bold text-left"
                  style={{ color: checkedBg(currentConfig?.colBg) }}
                >
                  {currentLocale['Slippage Tolerance']}
                </p>
              </div>
            )}
            {/* <div className=" flex flex-col"> */}
            {defiFailed.includes(statusTransaction) && (
              <SlippageComponent
                currentConfig={currentConfig}
                slippage={slippage}
                setSlippage={setSlippage}
                slippageArr={slippageArr}
              />
            )}
            {/* </div> */}
          </div>
          {/* block button */}
          <div className=" h-[20%] flex justify-center items-center">
            {(closeBtnCiFi.includes(statusTransaction) ||
              (closeBtnCiFiSuccess.includes(statusTransaction) &&
                typeDirect.includes(typeChanged))) && (
              <CloseBtn
                onClickHandler={onClickCloseBtn}
                currentConfig={currentConfig}
                text={currentLocale['Close']}
                spinnerState={spinnerState}
              />
            )}
            {closeBtnCiFiSuccess.includes(statusTransaction) &&
              typeHybrid.includes(typeChanged) && (
                <CloseBtn
                  onClickHandler={onClickContinueBtn}
                  currentConfig={currentConfig}
                  text={currentLocale['Continue DeFi Swap']}
                  spinnerState={spinnerState}
                />
              )}
            {successDeFi.includes(statusTransaction) && (
              <CloseBtn
                onClickHandler={onClickCloseBtn}
                currentConfig={currentConfig}
                text={currentLocale['Close']}
                spinnerState={spinnerState}
              />
            )}
            {defiFailed.includes(statusTransaction) && (
              <CloseBtn
                onClickHandler={onClickTryAgain}
                currentConfig={currentConfig}
                text={currentLocale['Try Again']}
                spinnerState={spinnerState}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

function SlippageComponent({
  currentConfig,
  setSlippage,
  slippage,
  slippageArr,
}) {
  const [slippageInput, setSlippageInput] = useState('');

  const handlerInput = (e) => {
    e.preventDefault();
    const regex = /^\d+(\.\d*)?$/;
    // console.log(e.target.value);
    // console.log(regex.test(e.target.value));
    if (e.target.value.trim() === '') {
      setSlippageInput(e.target.value);
      setSlippage({ index: 3, value: '' });
      // console.log(e.target.value);
      return;
    }
    if (
      e.target.value.trim() !== '' &&
      regex.test(e.target.value) &&
      parseFloat(e.target.value) >= 0 &&
      parseFloat(e.target.value) <= 100
    ) {
      setSlippageInput(e.target.value);
      setSlippage({ index: 3, value: parseFloat(e.target.value) / 100 });
      // console.log(e.target.value);
      return;
    }
  };
  return (
    <div className=" flex justify-center w-full">
      <div className=" flex justify-between items-center text-base w-[90%]">
        {slippageArr.map((el, index) => {
          return (
            <div key={index}>
              {index !== 3 ? (
                <p
                  key={uuid()}
                  className=" text-base font-bold text-center rounded-[15px] px-2 py-1 cursor-pointer w-16"
                  style={{
                    backgroundColor:
                      slippage?.index === el?.index
                        ? checkedBg(currentConfig?.colBg) + '50'
                        : checkedBg(currentConfig?.colBg) + '20',
                    color: checkedBg(currentConfig?.colBg),
                    borderColor: checkedBg(currentConfig?.colBg),
                  }}
                  onClick={() => {
                    setSlippage(el);
                    setSlippageInput('');
                  }}
                >
                  {el?.value * 100} %
                </p>
              ) : (
                <div className="flex gap-1 text-base font-bold ">
                  <input
                    className="  text-center items-center rounded-[15px] px-2 py-1 h-[30px] w-16 cursor-pointer outline-none"
                    style={{
                      backgroundColor: '#ffffff',

                      color: '#000000',
                      border: `1px solid ${checkedBg(currentConfig?.colBg)}`,
                    }}
                    type="text"
                    placeholder={String(0.5)}
                    value={slippageInput}
                    onChange={(e) => {
                      handlerInput(e);
                    }}
                    onClick={() => {
                      setSlippage(el);
                    }}
                  />
                  <div
                    className=" rounded-lg text py-1"
                    style={{
                      color: checkedBg(currentConfig?.colBg),
                      borderColor: checkedBg(currentConfig?.colBg),
                    }}
                  >
                    %
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {console.log()}
      </div>
    </div>
  );
}

// function TryBtn({ onClickHandler, currentConfig, text }) {
//   return (
//     <div
//       className={`flex justify-center items-center text-base font-lg w-[50%] h-[30px] mt-3 mx-auto rounded-full cursor-pointer transition-all duration-300`}
//       style={{
//         backgroundColor: currentConfig?.colBtn,
//         color: checkedBg(currentConfig?.colBtn),
//       }}
//       onMouseOver={(e) =>
//         (e.currentTarget.style.backgroundColor = currentConfig?.colBtn + '80')
//       }
//       onMouseOut={(e) => {
//         e.currentTarget.style.backgroundColor = currentConfig?.colBtn;
//       }}
//       onClick={() => {
//         onClickHandler();
//       }}
//     >
//       <p
//         className=" text-base"
//         style={{ color: checkedBg(currentConfig?.colBtn) }}
//       >
//         {text}
//       </p>
//     </div>
//   );
// }

function QuerySvgComponent({ onClickHandler }) {
  return (
    <div
      className=" w-7 h-7"
      onClick={() => {
        onClickHandler();
      }}
      onMouseOver={() => {}}
    >
      <QuestionSVG />
    </div>
  );
}

function CloseSvgComponent({ onClickHandler }) {
  return (
    <div
      className="w-5 h-5 cursor-pointer "
      onClick={() => {
        onClickHandler();
      }}
    >
      <CloseSVG />
    </div>
  );
}

function HeaderBlockText({ text, currentConfig }) {
  return (
    <h3
      className=" font-bold text-center text-lg mb-3"
      style={{ color: checkedBg(currentConfig?.colBg) }}
    >
      {text}
    </h3>
  );
}

function PlainTextBlockText({ text, currentConfig, statusTransaction }) {
  return (
    <div className=" flex flex-col justify-center items-center w-full">
      <p
        key={uuid()}
        className="text-base text-center "
        style={{
          color: checkedBg(currentConfig?.colBg),
          width: statusTransaction === 'failed_defi' ? '80%' : '65%',
        }}
      >
        {text}
      </p>
    </div>
  );
}

function CloseBtn({ onClickHandler, currentConfig, text, spinnerState }) {
  return (
    <div
      className={`flex justify-center items-center text-base font-lg w-[90%] h-[60px] rounded-full cursor-pointer transition-all duration-300`}
      style={{
        backgroundColor: currentConfig?.colBtn,
        color: checkedBg(currentConfig?.colBtn),
      }}
      onMouseOver={(e) =>
        (e.currentTarget.style.backgroundColor = currentConfig?.colBtn + '80')
      }
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = currentConfig?.colBtn;
      }}
      onClick={() => {
        onClickHandler();
      }}
    >
      {!spinnerState ? (
        <p className=" text-xl font-bold">{text}</p>
      ) : (
        <Spinner bgColor={checkedBg(currentConfig?.colBtn)} />
      )}
    </div>
  );
}
