import React from 'react';
import { ArrowDownSVG } from '../assets/svgStore';
import { useAppContext } from '../contextWrapper';
import { RenderImg } from './serviceComponents/renderImg';
import { useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';

export default function CurrencyButton({ typeCurrency }) {
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  const currentFiat = useSelector(unirampSelectors.getCurrentFiat());
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  const currentCurrency = typeCurrency === 'fiat' ? currentFiat : currentCrypto;

  return (
    <>
      {currentTheme && (
        <div
          className={` w-[130px] h-10 rounded-lg flex justify-between items-center al px-2 py-1 gap-2`}
          style={{
            borderColor: currentTheme.border.currency,
            backgroundColor: currentTheme.bg.currency,
          }}
        >
          <div className=" w-[28px]">
            <RenderImg currency={currentCurrency} />
          </div>
          <p
            className=" w-auto text-base text-center whitespace-nowrap overflow-hidden overflow-ellipsis"
            style={{ color: currentTheme.text.accent }}
          >
            {typeCurrency === 'fiat'
              ? currentCurrency?.id?.toUpperCase()
              : currentCurrency?.ticker?.toUpperCase()}
          </p>

          <div
            className=" w-[10px]"
            style={{ color: currentTheme.text.accent }}
          >
            <ArrowDownSVG />
          </div>
        </div>
      )}
    </>
  );
}
