import { useCallback, useContext, useEffect } from 'react';
import '../app.css';
import { MainContext } from './contexts/mainContext';
import { MainScreen } from './mainScreen';

import { useInitialProperties } from './hooks/useInitialProperties';

import {
  useLazyGetAllCeFiPaymentQuery,
  useLazyGetCryptoListQuery,
  useLazyGetCurrentIPQuery,
  useLazyGetFiatListQuery,
} from './store/uniramp/uniramp.api';

import { useGetTransactions } from './hooks/useGetTransactions';
import { useGetCiFiOnramper } from './hooks/useGetCeFiOnRamper';
import { useWalletChanged } from './hooks/useWalletChanged';
import unirampSelectors from './store/uniramp/uniramp.selector';
import { useDispatch, useSelector } from 'react-redux';
import { unirampActions } from './store/uniramp/uniramp.slice';

export const ContextWrapper = (props) => {
  const dispatch = useDispatch();
  useInitialProperties(props);
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (currentConfig && !currentConfig?.apiKey) {
  //     dispatch(unirampActions.setIsAuth(false));
  //   }
  // }, [currentConfig, dispatch]);

  const isAuth = useSelector(unirampSelectors.getIsAuth());
  const [getFiatList] = useLazyGetFiatListQuery();
  const [getCurrentIPQuery] = useLazyGetCurrentIPQuery();
  const [getCryptoList] = useLazyGetCryptoListQuery();
  const [getAllCeFiPayment] = useLazyGetAllCeFiPaymentQuery();

  const memoApiQuery = useCallback(async () => {
    isAuth && (await getFiatList());
    isAuth && (await getCurrentIPQuery());
    isAuth && (await getCryptoList());
    isAuth && (await getAllCeFiPayment());
  }, [
    getAllCeFiPayment,
    getCryptoList,
    getCurrentIPQuery,
    getFiatList,
    isAuth,
  ]);

  useEffect(() => {
    if (currentConfig?.apiKey) {
      memoApiQuery();
    }
  }, [memoApiQuery, currentConfig?.apiKey]);

  useGetCiFiOnramper();

  useWalletChanged();
  useGetTransactions();

  return (
    <>
      <MainScreen />
    </>
  );
};

export const useAppContext = () => {
  const context = useContext(MainContext);
  return context;
};
