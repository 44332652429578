import React from 'react';
import { colorPage } from '../../assets/colorSet';

export const Divider = () => {
  return (
    <div
      className={' w-[100%] h-[0px] border'}
      style={{ borderBottomColor: colorPage.accent }}
    />
  );
};
