export const sizeForPopup = {
  leftMenu: {
    height: '510px',
    width: '327px',
  },
  currencyMenu: {
    height: '455px',
    width: '364px',
  },
  gatewayMenu: {
    height: '475px',
    width: '364px',
  },
  paymentMenu: {
    height: '475px',
    width: '364px',
  },
  buyMenu: {
    height: '475px',
    width: '364px',
  },
};
