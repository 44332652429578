import React from 'react';
import '../app.css';

import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { ContextWrapper } from './contextWrapper';
import { createHTTPClient } from './services/createHTTPClient';

//-------------------------------------------------------------

//-------------------------------------------------------------

function WidgetApp(props) {
  const { wagmiClient, chains, provider } = createHTTPClient();
  return (
    <>
      {wagmiClient && (
        <>
          <WagmiConfig client={wagmiClient} provider={provider}>
            <RainbowKitProvider chains={chains}>
              {wagmiClient && <ContextWrapper {...props} />}
            </RainbowKitProvider>
          </WagmiConfig>
        </>
      )}
    </>
  );
}

export default WidgetApp;
