import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ContinuePayment } from './continuePayment';
import { useAppContext } from '../contextWrapper';
import { RenderImg } from './serviceComponents/renderImg';
import { checkedBg } from '../services/checkedBg';
import { DrawerComponent } from './serviceComponents/drawerComponent';
// import { ChoiceWalletModal } from './modals/choiceWalletModal';
import { getNextStepIFrame } from '../services/queries';
import { AvatarIconDefault, MetaMaskSVG } from '../assets/svgStore';
import { WalletConnectSVG } from '../assets/svgStore';
import { Spinner } from './serviceComponents/spinner';
import { v4 as uuid } from 'uuid';
import qs from 'qs';
// import SpinnerMoonpay from './SpinnerMoonpay';

//rainbow ------------------------------
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import TransactionHistory from './TransactionHistory';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase.config';
import { auth } from '../../firebase.config';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { addressNativeToken } from '../assets/addressNativeToken';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { useDispatch, useSelector } from 'react-redux';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import { usePostTransactionMutation } from '../store/uniramp/uniramp.api';
//--------------------------------------

export default function AcceptButton({ typeCurrency }) {
  const dispatch = useDispatch();
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());

  const currentFiat = useSelector(unirampSelectors.getCurrentFiat());
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  const isGatewaysListStatus = useSelector(
    unirampSelectors.isGatewaysListStatus()
  );

  const isGatewaysListStatusDec = useSelector(
    unirampSelectors.getIsCurrentGatewaysListDec()
  );
  const currentAmount = useSelector(unirampSelectors.getCurrentAmount());
  const currentGatewayDec = useSelector(
    unirampSelectors.getCurrentGatewayDec()
  );
  const currentPayment = useSelector(unirampSelectors.getCurrentPayment());
  const idToken = useSelector(unirampSelectors.getIdToken());
  const userId = useSelector(unirampSelectors.getUserId());
  const wallet = useSelector(unirampSelectors.getWallet());
  const isOpenIFrame = useSelector(unirampSelectors.getIsOpenIFrame());

  const [isPressBtn, setIsPressBtn] = useState(true);

  const [isOpenBuyingPart, setIsOpenBuyingPart] = useState(false);
  useEffect(() => {
    setIsOpenBuyingPart(isOpenIFrame);
  }, [isOpenIFrame]);
  useEffect(() => {
    dispatch(unirampActions.setIsOpenIFrame(isOpenBuyingPart));
  }, [dispatch, isOpenBuyingPart]);

  //rainbow ------------------------------
  const { openConnectModal } = useConnectModal();
  const { address, status } = useAccount();
  useEffect(() => {
    if (address && status !== 'disconnected' && status !== 'connecting') {
      dispatch(unirampActions.setWallet(address));
    } else dispatch(unirampActions.setWallet(address));
  }, [address, dispatch, status]);

  //--------------------------------------

  const refBtn = useRef();

  const currentCurrency = typeCurrency === 'fiat' ? currentFiat : currentCrypto;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpenLoading, setIsOpenLoading] = React.useState(false);

  // const [isOpenMenuAvatar, setIsOpenMenuAvatar] = eReact.useState(false);

  const [popupWindow, setPopupWindow] = useState(null);
  const [isPopupClosed, setIsPopupClosed] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        setIsPopupClosed(false);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [popupWindow]);

  const checkAuth = useCallback(async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      const user = userCredential.user;

      const idToken = await user.getIdToken();

      const userId = user.uid;

      dispatch(unirampActions.setUserId(userId));
      dispatch(unirampActions.setIdToken(idToken));

      const docRef = doc(db, 'wallets', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().wallet === address) {
        if (docSnap.data().idToken !== idToken) {
          await updateDoc(doc(db, 'wallets', userId), {
            idToken: idToken,
          });
        }
        dispatch(unirampActions.setIsAuthFirebase(true));
      } else {
        await setDoc(doc(db, 'wallets', userId), {
          wallet: address,
          userId: userId,
          idToken: idToken,
        });
        dispatch(unirampActions.setIsAuthFirebase(true));
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [address, dispatch]);

  useEffect(() => {
    if (address) {
      checkAuth();
    } else {
      dispatch(unirampActions.resetFbSettings());
    }
  }, [address, checkAuth, dispatch]);

  // const onClickBuyBtn = async (e) => {
  //   e.preventDefault();

  //   const currentBody = {
  //     cryptocurrencyAddress: address,
  //     partnerContext: {
  //       identifier: 'uniramp-beta',
  //       transactionId: uuid(),
  //       bypassIdentifier: currentGateway?.identifier,
  //       sellTokenSymbol:
  //         addressNativeToken[currentCrypto?.new?.networkCoin].name,
  //       sellTokenAddress:
  //         addressNativeToken[currentCrypto?.new?.networkCoin].address,
  //       sellAmount: currentGateway?.receivedCrypto,
  //       takerAddress: address,
  //       buyTokenSymbol: currentCrypto?.new?.ticker,
  //       buyTokenAddress: currentCrypto?.new?.contract,
  //       buyTokenNetwork: currentCrypto?.new?.networkCoin,
  //       bypassRamperId:
  //         currentCrypto?.new?.filterBypass !== 'yes' ? '0x.org' : 'byOnRamper',
  // token: walletToken,
  // idToken: idTokenValue,
  //     },
  //   };

  //   console.log('currentGateway', currentGateway);

  //   if (currentGateway && currentGateway?.identifier === 'Moonpay') {
  //     const urlObject = await getNextStepIFrame(currentGateway, currentBody);

  //     setCurrentIFrame(urlObject.url);

  //     const newWindow = window.open(
  //       urlObject.url,
  //       '_blank',
  //       'left=600,top=100, width=400,height=600 '
  //     );

  //     setPopupWindow(newWindow);

  //     newWindow.onunload = () => {
  //       setIsPopupClosed(true);
  //     };
  //   } else {
  //     if (currentGateway) {
  //       setIsLoading(true);
  //       setIsOpenLoading(true);
  //       const res = await getNextStepIFrame(currentGateway, currentBody);
  //       if (res && currentCrypto) {
  //         setCurrentIFrame(res);
  //         setIsLoading(false);
  //         setIsOpenLoading(false);
  //         setIsOpenBuyingPart(true);
  //         setCurrentScreen(<MainChargerScreen />);
  //       }
  //     }
  //   }
  // };

  const [postTransaction] = usePostTransactionMutation();
  const onClickBuy = async () => {
    setIsPressBtn(false);
    console.log(currentFiat);
    let body = {
      cefiGateway: currentGatewayDec?.gateway,
      fiat: currentFiat?.id,
      fiatAmount: currentAmount * Math.pow(10, currentFiat?.decimal),
      payment: currentPayment?.id,
      chain: currentCrypto?.chain,
      wallet: wallet,
      partnerContext: { idToken: idToken, userId: userId },
    };

    if (currentCrypto?.address) {
      body = { ...body, crypto: currentCrypto?.address };
    }

    if (currentGatewayDec?.type === 'hybrid') {
      body = { ...body, defiGateway: currentGatewayDec?.gatewayDiFi };
    }

    const res = await postTransaction(body);
    if (!res?.error) {
      setIsOpenBuyingPart(true);
      setIsPressBtn(true);
    } else {
      setIsPressBtn(true);
    }
  };

  return (
    <>
      {(isLoading || isPopupClosed) && (
        <>
          <DrawerComponent
            isOpen={isPopupClosed ? isPopupClosed : isOpenLoading}
            setIsOpen={isPopupClosed ? '' : setIsOpenLoading}
            position={'down'}
            menuSize={'100%'}
            closeBtn={false}
          >
            <div
              className=" w-[100%] h-[540px] flex justify-center items-center"
              style={{ backgroundColor: currentTheme.bg.main }}
            >
              <div style={{ color: currentTheme.text.accent }}>
                <Spinner bgColor={checkedBg(currentConfig?.colBg)} />
              </div>
            </div>
          </DrawerComponent>
        </>
      )}
      <>
        {currentCurrency && address ? (
          <>
            {/* <div className="relative">
              <div
                style={{
                  color: checkedBg(currentConfig?.colBg),
                  bottom: 370,
                  left: 275,
                }}
                className="cursor-pointer items-center absolute "
                onClick={() => setIsOpenMenuAvatar(true)}
              >
                <AvatarIconDefault />
              </div>
            </div> */}
            <button
              ref={refBtn}
              className={`w-full h-14 rounded-lg hover:focus:outline-none transition-all duration-300 ease-in-out flex justify-center items-center`}
              // onClick={(e) => {
              //   if (isSuccessInfo === 'success') {
              //     onClickBuyBtn(e);
              //   }
              // }}
              onClick={() => {
                isGatewaysListStatus === 'success' &&
                  isPressBtn &&
                  onClickBuy();
              }}
              style={{
                backgroundColor: currentConfig.colBtn,
                color: checkedBg(currentConfig?.colBtn),
              }}
              onMouseOver={e =>
                (e.currentTarget.style.backgroundColor =
                  currentConfig.colBtn + '80')
              }
              onMouseOut={e => {
                e.currentTarget.style.backgroundColor = currentConfig.colBtn;
              }}
            >
              {isGatewaysListStatusDec !== 'pending' && isPressBtn ? (
                <div
                  className={`flex gap-3 justify-center items-center bg-transparent!important`}
                >
                  <p className={` font-bold text-base`}>
                    {currentLocale['Buy']}
                  </p>
                  <RenderImg currency={currentCurrency} />
                  <p className={`font-bold text-base uppercase`}>
                    {currentCurrency?.ticker}
                  </p>
                </div>
              ) : (
                <Spinner bgColor={checkedBg(currentConfig?.colBtn)} />
              )}
            </button>
          </>
        ) : (
          <button
            ref={refBtn}
            className={`w-full h-14 rounded-lg hover:focus:outline-none transition-all duration-300 ease-in-out flex justify-center items-center`}
            onClick={openConnectModal}
            style={{
              backgroundColor: currentConfig.colBtn,
              color: checkedBg(currentConfig?.colBtn),
            }}
            onMouseOver={e =>
              (e.currentTarget.style.backgroundColor =
                currentConfig.colBtn + '80')
            }
            onMouseOut={e => {
              e.currentTarget.style.backgroundColor = currentConfig.colBtn;
            }}
          >
            {!address && (
              <div className=" flex items-center">
                <p className=" text-base font-bold">
                  {currentLocale['Connect Wallet']}
                </p>
                <div className=" w-8 h-8 flex items-center">
                  <MetaMaskSVG />
                </div>
                <div className=" w-6 h-6 flex items-center">
                  <WalletConnectSVG />
                </div>
              </div>
            )}
          </button>
        )}
      </>

      {isOpenBuyingPart && (
        <DrawerComponent
          isOpen={isOpenBuyingPart}
          setIsOpen={setIsOpenBuyingPart}
          position={'down'}
          menuSize={'93%'}
          x={'10px'}
          y={'10px'}
        >
          <ContinuePayment />
        </DrawerComponent>
      )}

      {/* <DrawerComponent
        isOpen={isOpenMenuAvatar}
        setIsOpen={setIsOpenMenuAvatar}
        position={'right'}
        menuSize={'90%'}
      >
        <TransactionHistory
          setIsOpenMenuAvatar={setIsOpenMenuAvatar}
          isOpenMenuAvatar={isOpenMenuAvatar}
        />
      </DrawerComponent> */}
    </>
  );
}
