import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import uniramp from './uniramp/uniramp.slice';
import { configureStore } from '@reduxjs/toolkit';
import { unirampApi } from './api_service/uniramp_api';

const unirampConfig = {
  key: 'uniramp',
  storage,
  whitelist: ['id_transaction'],
};

const store = configureStore({
  reducer: {
    uniramp: persistReducer(unirampConfig, uniramp.reducer),
    [unirampApi.reducerPath]: unirampApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      unirampApi.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;
