import React, { useEffect } from 'react';
import { defaultConfigStore } from '../../../widget/assets/storage';
import {
  getDefaultCurrencies,
  getDefaultCurrenciesFb,
  queryToUnirampApi,
} from '../../../widget/services/queries';
import { colorPage } from '../../assets/colorSet';
import { themesStorage } from '../../assets/themesStorage';
import { localesStorage } from '../../assets/localesStorage';
import { MenuElement } from '../serviceComponents/menuElement';
import { Divider } from '../serviceComponents/divider';
import { usePageContext } from '../../pageConfig';
import { paymentsStorage } from '../../../widget/assets/paymentsStorage';
import RecieveConfiguration from '../serviceComponents/RecieveConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../../../widget/store/uniramp/uniramp.selector';
import { unirampActions } from '../../../widget/store/uniramp/uniramp.slice';

const menus = [
  { name: 'theme', visible: false },
  { name: 'lang', visible: false },
  { name: 'fiat', visible: false },
  { name: 'method', visible: false },
  { name: 'token', visible: false },
];

export const BaseSetting = ({ config, setConfig }) => {
  const { pageLocale } = usePageContext();
  const dispatch = useDispatch();
  const apiKeyConfigPage = useSelector(unirampSelectors.getApiKeyConfigPage());

  // const [currencies, setCurrencies] = React.useState(null);
  // const [listFiat, setListFiat] = React.useState(null);
  // const [currentFiat, setCurrentFiat] = React.useState(null);
  // const [listCrypto, setListCrypto] = React.useState(null);
  // const [currentCrypto, setCurrentCrypto] = React.useState(null);
  const [listThemes, setListThemes] = React.useState(themesStorage);
  const [currentTheme, setCurrentTheme] = React.useState(themesStorage[0]);
  const [listLocales, setListLocales] = React.useState(localesStorage);
  const [currentLocale, setCurrentLocale] = React.useState(localesStorage[0]);
  const [currentMainBg, setCurrentMainBg] = React.useState(null);
  const [currentButtonBg, setCurrentButtonBg] = React.useState(null);
  // const [paymentList, setPaymentList] = React.useState(
  //   paymentsStorage('#FFFFFF')?.filter((el) => el.code !== 'menu')
  // );
  // const [currentPaymentMethod, setCurrentPaymentMethod] = React.useState(
  //   paymentsStorage('#FFFFFF')[0]
  // );
  const [isOpenMenus, setIsOpenMenus] = React.useState(null);

  useEffect(() => {
    setIsOpenMenus(menus);
  }, []);

  // const filterArr = (data) => {
  //   return data
  //     .filter(
  //       (obj, index, self) =>
  //         index === self.findIndex((t) => t.code === obj.code)
  //     )
  //     .sort((a, b) => a.code.localeCompare(b.code));
  // };

  // const memoizeGetDefaultCurrency = React.useCallback(async () => {
  //   const data = await getDefaultCurrencies();

  //   if (!data?.message) return;

  //   const { message: res } = data;
  //   setCurrencies(res);
  //   setListFiat(filterArr(res?.fiat));
  //   setCurrentFiat(
  //     res?.fiat.find((el) => el.code === defaultConfigStore()?.initFiat)
  //   );

  //   const newCarrancy = await getDefaultCurrenciesFb();
  //   // console.log('new ----', newCarrancy);
  //   const listCryptoFromFb = newCarrancy?.map((el) => {
  //     // console.log('newCarrancy', newCarrancy);
  //     return {
  //       id: el.contract,
  //       code: el.ticker,
  //       name: el.name,
  //       symbol: el.tagCoinInfo,
  //       network: el.networkCoin,
  //       icon: el.image[0].downloadURL,
  //       new: { ...el },
  //     };
  //   });

  //   setListCrypto(filterArr(listCryptoFromFb));
  //   setCurrentCrypto(
  //     listCryptoFromFb.find(
  //       (el) => el.code === defaultConfigStore()?.initCrypto
  //     )
  //   );
  // }, []);

  // React.useEffect(() => {
  //   if (!currencies) {
  //     memoizeGetDefaultCurrency();
  //   }
  // }, [currencies, memoizeGetDefaultCurrency]);

  // React.useEffect(() => {
  //   if (currentFiat) {
  //     setConfig((prev) => {
  //       return { ...prev, initFiat: currentFiat.code };
  //     });
  //   }
  // }, [currentFiat, setConfig]);

  // React.useEffect(() => {
  //   if (currentCrypto) {
  //     setConfig((prev) => {
  //       return { ...prev, initCrypto: currentCrypto?.code };
  //     });
  //   }
  // }, [currentCrypto, setConfig]);

  React.useEffect(() => {
    if (currentTheme) {
      setConfig((prev) => {
        return { ...prev, theme: currentTheme.code };
      });
      setCurrentMainBg(
        themesStorage.find((el) => el.code === currentTheme.code).colors.bg.main
      );
      setCurrentButtonBg(
        themesStorage.find((el) => el.code === currentTheme.code).colors.btn
          .bgBtn
      );
      setConfig((prev) => {
        return {
          ...prev,
          theme: currentTheme.code,
          colBg: themesStorage.find((el) => el.code === currentTheme.code)
            .colors.bg.main,
          colBtn: themesStorage.find((el) => el.code === currentTheme.code)
            .colors.btn.bgBtn,
        };
      });
    }
  }, [currentTheme, setConfig]);

  React.useEffect(() => {
    if (currentLocale) {
      setConfig((prev) => {
        return { ...prev, lang: currentLocale.code };
      });
    }
  }, [currentLocale, setConfig]);

  // React.useEffect(() => {
  //   if (currentPaymentMethod) {
  //     setConfig((prev) => {
  //       return { ...prev, initPayment: currentPaymentMethod.code };
  //     });
  //   }
  // }, [currentPaymentMethod, setConfig]);

  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  const cryptoList = useSelector(unirampSelectors.getCryptoList());

  return (
    <>
      {colorPage &&
        listThemes &&
        listLocales &&
        currentMainBg &&
        currentButtonBg && (
          <div className={`flex flex-col gap-3`}>
            <div className={`flex flex-col gap-3`}>
              <div className="flex base:flex-col md:flex-row base:gap-4 md:gap-10">
                <MenuElement
                  title={pageLocale['Choice Theme']}
                  currentData={currentTheme}
                  setCurrentData={setCurrentTheme}
                  currentList={listThemes}
                  type={'simpleList'}
                  name={'theme'}
                  isOpenMenu={isOpenMenus}
                  setIsOpenMenu={setIsOpenMenus}
                />
                <div
                  className={`base:w-[100%] base:hidden md:w-[50%] md:block min-h-fit`}
                ></div>
              </div>
              <div className="flex base:flex-col md:flex-row base:gap-4 md:gap-10">
                {/* ToDo вынести колорпикеры в один сервисный компонент */}
                <div className={`base:w-[100%] md:w-[50%] min-h-fit`}>
                  <label className={` text-[${colorPage.text}]`}>
                    {pageLocale['Choice Color Background']}
                  </label>
                  <input
                    type={`color`}
                    className={`w-[100%] h-[48px] mt-2 rounded-lg bg-[${colorPage?.bgInput}] py-2 px-4 outline-none`}
                    value={currentMainBg}
                    onChange={(e) => {
                      setConfig((prev) => {
                        return {
                          ...prev,
                          colBg: e.target.value,
                        };
                      });
                      setCurrentMainBg(e.target.value);
                    }}
                  />
                </div>
                <div className={`base:w-[100%] md:w-[50%] min-h-fit`}>
                  <label className={` text-[${colorPage.text}]`}>
                    {pageLocale['Choice Color Button']}
                  </label>
                  <input
                    type={`color`}
                    className={`w-[100%] h-[48px] mt-2 rounded-lg bg-[${colorPage?.bgInput}] py-2 px-4 outline-none`}
                    value={currentButtonBg}
                    onChange={(e) => {
                      setConfig((prev) => {
                        return { ...prev, colBtn: e.target.value };
                      });
                      setCurrentButtonBg(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <Divider />
            <div className="flex gap-10">
              <MenuElement
                title={pageLocale['Choice Language']}
                currentData={currentLocale}
                setCurrentData={setCurrentLocale}
                currentList={listLocales}
                type={'simpleList'}
                name={'lang'}
                isOpenMenu={isOpenMenus}
                setIsOpenMenu={setIsOpenMenus}
              />
              <div
                className={`base:w-[100%] base:hidden md:w-[50%] md:block min-h-fit`}
              ></div>
            </div> */}
            <Divider />
            <div className="flex base:flex-col base:gap-4 md:flex-row md:gap-10">
              {/* <div className={`base:w-[100%] md:w-[50%] min-h-fit`}>
                <label className={` text-[${colorPage.text}]`}>
                  {pageLocale['Initial Amount Fiat']}
                </label>
                <input
                  type={`number`}
                  defaultValue={'100'}
                  min={'0'}
                  className={`w-[100%] h-[48px] outline-none mt-2 rounded-lg bg-[${colorPage?.bgInput}] py-2 px-4 border border-[${colorPage?.bgInput}]`}
                  onChange={(e) =>
                    setConfig((prev) => {
                      return {
                        ...prev,
                        initAmountFiat: e.target.value,
                      };
                    })
                  }
                />
              </div> */}
              {/* <div className={`base:w-[100%] md:w-[50%] min-h-fit`}></div> */}
              {/* <MenuElement
                title={pageLocale['Initial Fiat']}
                currentData={currentFiat}
                currentList={listFiat}
                setCurrentData={setCurrentFiat}
                type={'currency'}
                withInput
                name={'fiat'}
                isOpenMenu={isOpenMenus}
                setIsOpenMenu={setIsOpenMenus}
              /> */}
            </div>
            <div className="flex gap-10 base:flex-col-reverse base:gap-4 md:flex-row md:gap-10">
              {/* <MenuElement
                title={pageLocale['Payment Method']}
                currentData={currentPaymentMethod}
                currentList={paymentList}
                setCurrentData={setCurrentPaymentMethod}
                type={'payment'}
                name={'method'}
                isOpenMenu={isOpenMenus}
                setIsOpenMenu={setIsOpenMenus}
              /> */}

              <RecieveConfiguration
                title={pageLocale['Initial Crypto']}
                currentData={currentCrypto}
                currentList={cryptoList}
                // setCurrentData={setCurrentCrypto}
                type={'currency'}
                withInput
                name={'token'}
                typeCurrency={'crypto'}
                isOpenMenu={isOpenMenus}
                setIsOpenMenu={setIsOpenMenus}
              />
              <div className={`base:w-[100%] md:w-[50%] min-h-fit`}>
                <p className=" text-base">API Key</p>
                <input
                  name="apiKey"
                  className=" border rounded-lg mt-2 px-4 py-2 w-full h-12 outline-none"
                  // placeholder="pk_test_C5ayW4c9DhlY7iCVu82p4g6h"
                  value={apiKeyConfigPage}
                  onChange={(e) => {
                    dispatch(
                      unirampActions.setApiKeyConfigPage(e.target.value)
                    );
                  }}
                />
              </div>
              {/* <div className={`base:w-[100%] md:w-[50%] min-h-fit`}></div> */}
              {/* <MenuElement
                title={pageLocale['Initial Crypto']}
                currentData={currentCrypto}
                currentList={listCrypto}
                setCurrentData={setCurrentCrypto}
                type={'currency'}
                withInput
                name={'token'}
                isOpenMenu={isOpenMenus}
                setIsOpenMenu={setIsOpenMenus}
              /> */}
            </div>
          </div>
        )}
    </>
  );
};
