import Web3 from 'web3';
import { countGas, createParams } from './sendTransactionBy0xApproval';
import { fetch0x } from './feth0x';
import {
  ONRAMPER_KEY,
  UNIRAMP_API_APIKEY,
  UNIRAMP_API_URL,
  UNIRAMP_HEADER_FIELD,
} from '../assets/apiKeys';
import axios from 'axios';

export const queryToUnirampApi = async ({ method, path, queryParams }) => {
  try {
    const res = await axios[method](UNIRAMP_API_URL + path + queryParams, {
      headers: { [`${UNIRAMP_HEADER_FIELD}`]: UNIRAMP_API_APIKEY },
    });
    return res?.data ? res.data : { error: 'error in queryToUnirampApi' };
  } catch (error) {
    console.log(error);
  }
};

export function getDefaultCurrencies() {
  try {
    // return fetch('https://api.onramper.com/supported', {
    //   headers: {
    //     Authorization: 'pk_prod_01GSQW6H14HA2JGVCRC1X7TW2M',
    //   },
    //   cache: 'no-cache',
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     return err;
    //   });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getDefaultCurrenciesFb() {
  try {
    const res = await fetch(
      'https://us-central1-uniramp-beta.cloudfunctions.net/getListCrypto',
      {
        cache: 'no-cache',
      }
    );
    const data = await res.json();
    const result = data?.result;
    return result;
  } catch (error) {
    console.log(error);
  }
}

export const getRates = async (
  fromCurrency = 'EUR',
  toCurrency = 'ETH',
  paymentMethod = 'creditCard',
  amount = '100'
) => {
  try {
    // const res = await fetch(
    //   `https://onramper.tech/rate/${fromCurrency}/${toCurrency}/${paymentMethod}/${amount}?includeIcons=true`,
    //   { headers: { Authorization: ONRAMPER_KEY } }
    // );
    // const data = await res.json();
    // const sortedData = data?.filter((el) => !el.error);
    // return sortedData;
  } catch (error) {}
};

export const getNextStepIFrame = (currentGateway, currentBody) => {
  console.log('currentBody', currentBody);
  console.log('currentGateway', currentGateway);
  try {
    return fetch(currentGateway?.nextStep.url, {
      headers: { Authorization: ONRAMPER_KEY },
      method: 'POST',
      body: JSON.stringify(currentBody),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('data', data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};

//-----------------------------------

export const getSwap0X = async (currentCrypto, receivedETH) => {
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');

  if (currentCrypto?.new?.filterBypass === 'yes') {
    const { receivedCrypto, rate } = receivedETH;
    return { receivedCrypto, rate };
  } else {
    const configObj = {
      takerAddress: '',
      sellToken:
        currentCrypto?.new?.networkCoin === 'ETH'
          ? '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
          : currentCrypto?.new?.networkCoin,
      buyTokenAddress: currentCrypto?.new?.tokenAddress,
      sellAmount: web3.utils.toWei(String(receivedETH?.receivedCrypto)),
      // skipValidation: true,
    };
    // console.log('configObj in getSwap0x', configObj);

    const paramsPrice = await createParams(web3, configObj, 'price');
    // console.log('paerams', paramsPrice);

    const getPrice = await fetch0x(
      paramsPrice,
      'price',
      currentCrypto?.new?.networkCoin
    );
    // console.log('getPrice', getPrice);
    if (getPrice?.validationErrors) {
      return { takeAmount: undefined, rateEthToToken: undefined };
    } else {
      const gasEstimated = await countGas(
        web3,
        getPrice,
        currentCrypto?.new?.networkCoin
      );

      const takeAmount =
        getPrice?.price *
        (receivedETH?.receivedCrypto -
          (await web3.utils.fromWei(String(gasEstimated, 'ether'))));
      const rateEthToToken = getPrice?.price;
      return { takeAmount, rateEthToToken };
    }
  }
};
