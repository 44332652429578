import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase.config';

export const sendTransactionToFb = async ({ prevTransaction, data }) => {
  try {
    console.log('send transaction to FB prevTransaction', prevTransaction);
    console.log('send transaction to FB data', data);
    const docRef = await addDoc(collection(db, 'transactions'), {
      data: {
        transactionId: prevTransaction?.id,
        gateway: data?.gateway,
        cryptoAmount: '',
        txId: '',
        wallet: prevTransaction?.wallet,
        cryptoDecimal: 18,
        status: data?.status,
        fiatDecimal: 2,
        payment: 'defi',
        crypto: '',
        chain: 'eth',
        fiat: prevTransaction?.data?.fiat,
        fiatAmount: prevTransaction?.data?.fiatAmount,
        country: prevTransaction?.data?.country
          ? prevTransaction?.data?.country
          : 'www',
      },
      id: prevTransaction?.id,
      wallet: prevTransaction?.wallet,
      type: 'onramp_defi_transaction',
    });
    return docRef;
  } catch (error) {
    console.log('error', error);
  }
};
