import { useCallback, useEffect, useState } from 'react';
import CurrencyButton from './currencyButton';
import MenuCurrencies from './menuCurrensies';
import { DrawerComponent } from './serviceComponents/drawerComponent';
import { Spinner } from './serviceComponents/spinner';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { unirampActions } from '../store/uniramp/uniramp.slice';

import { checkedBg } from '../services/checkedBg';

export function convertNumber(number) {
  const removeZero = number => {
    return String(parseFloat(number));
  };
  const temp = parseFloat(number);
  if (temp) {
    if (temp >= 1) {
      return removeZero(temp.toFixed(5));
    } else {
      const decimalPlaces = Math.min(5, Math.abs(temp).toString().length - 2);
      return removeZero(temp.toFixed(decimalPlaces).replace('.', '.'));
    }
  }
}

export default function CurrencyInfoBox({ text, typeCurrency }) {
  const dispatch = useDispatch();
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  const currentAmount = useSelector(unirampSelectors.getCurrentAmount());

  const currentGatewayDec = useSelector(
    unirampSelectors.getCurrentGatewayDec()
  );
  const isGatewaysListStatusDec = useSelector(
    unirampSelectors.getIsCurrentGatewaysListDec()
  );
  const pageBySell = useSelector(unirampSelectors.getPageBySell());
  const currentFiat = useSelector(unirampSelectors.getCurrentFiat());
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  const currentPayment = useSelector(unirampSelectors.getCurrentPayment());
  const wallet = useSelector(unirampSelectors.getWallet());

  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [inputState, setInputState] = useState(null);

  useEffect(() => {
    setInputState(currentAmount);
  }, [currentAmount]);

  const handleAmountChange = useCallback(
    debounce(value => {
      dispatch(unirampActions.setCurrentAmount(value));
    }, 500),
    []
  );

  const onChange = value => {
    setInputState(value);
    handleAmountChange(value);
  };

  function onScroll(e) {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  }

  return (
    <>
      {typeCurrency && (
        <div
          className={
            pageBySell === 'Buy'
              ? ` h-[85px] rounded-t-lg px-2 pb-2 pt-2 ${
                  typeCurrency !== 'crypto' ? ' rounded-b-lg mb-2' : 'mb-1'
                }`
              : ` h-[90px] rounded-t-lg p-4 ${
                  typeCurrency !== 'crypto'
                    ? '  rounded-t-lg  mb-1'
                    : 'rounded-b-lg  mb-4'
                }`
          }
          style={{ backgroundColor: currentTheme.bg.section }}
        >
          <p
            style={{ color: currentTheme.text.plain }}
            className="text-base text-left"
          >
            {text}
          </p>
          <div className={`flex justify-between`}>
            {/* {['waiting', 'pending'].includes(isLoadingGateways) && */}
            {isGatewaysListStatusDec === 'pending' &&
            typeCurrency === 'crypto' ? (
              <div className="flex items-center justify-left relative">
                <div className=" w-[100%] flex items-center justify-left scale-50 absolute">
                  <Spinner bgColor={checkedBg(currentConfig?.colBg)} />
                </div>
              </div>
            ) : (
              <>
                {/* {['waiting', 'pending'].includes(isLoadingReceivedCrypto) && */}
                {/* {false && text !== 'Spend' ? (
                  <div className="flex items-center justify-left relative">
                    <div className=" w-[100%] flex items-center justify-left scale-50 absolute">
                      <Spinner />
                    </div>
                  </div>
                ) : (
                  <> */}
                {text !== 'Spend' ? (
                  <div
                    className={`w-[120px] bg-transparent outline-none text-lg flex items-center`}
                    style={{ color: currentTheme.text.accent }}
                  >
                    <p className=" text-base">
                      {convertNumber(currentGatewayDec?.decimalResult)}
                    </p>
                  </div>
                ) : (
                  <>
                    {inputState !== null && (
                      <input
                        className={`w-[120px] bg-transparent outline-none text-lg`}
                        min={'0'}
                        type={'number'}
                        onWheel={e => onScroll(e)}
                        placeholder={'0'}
                        value={inputState}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        style={{ color: currentTheme.text.accent }}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {/* </> */}
            {/* )} */}
            <div
              onClick={() => setIsOpenCurrency(true)}
              className={`cursor-pointer`}
            >
              <CurrencyButton typeCurrency={typeCurrency} />
            </div>
          </div>
        </div>
      )}
      {isOpenCurrency && (
        <DrawerComponent
          isOpen={isOpenCurrency}
          setIsOpen={setIsOpenCurrency}
          menuSize={'90%'}
        >
          <MenuCurrencies
            typeCurrency={typeCurrency}
            isOpen={isOpenCurrency}
            setIsOpen={setIsOpenCurrency}
          />
        </DrawerComponent>
      )}
      {/* <Drawer
        isOpen={isOpenCurrency}
        setIsOpen={setIsOpenCurrency}
        position={'down'}
      >
        <MenuCurrencies
          typeCurrency={typeCurrency}
          setIsOpen={setIsOpenCurrency}
        />
      </Drawer> */}
    </>
  );
}
