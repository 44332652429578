import React, { useEffect } from 'react';
import { useAppContext } from '../../../widget/contextWrapper';
import {
  ArrowDownSVG,
  CheckMarkSVG,
  SearchSVG,
} from '../../../widget/assets/svgStore';
import { colorPage } from '../../assets/colorSet';
import { checkedBg } from '../../../widget/services/checkedBg';
import { useDispatch } from 'react-redux';
import { unirampActions } from '../../../widget/store/uniramp/uniramp.slice';

export default function ReceiveConfiguration({
  title,
  currentData,
  setCurrentData = () => {},
  currentList,
  type,
  withInput = false,
  name,
  isOpenMenu,
  setIsOpenMenu,
  typeCurrency,
}) {
  const [currentQuery, setCurrentQuery] = React.useState('');
  const dispatch = useDispatch();

  const [currentListFiltered, setCurrentListFiltered] = React.useState([]);
  const [indexMenu, setIndexMenu] = React.useState(0);
  const [selectedNetwork, setSelectedNetwork] = React.useState([]);

  const networkOrder = [
    { name: 'ETH', net: 'eth', chainId: 1 },
    { name: 'BSC', net: 'bsc', chainId: 56 },
    { name: 'POLY', net: 'poly', chainId: 137 },
    { name: 'Non-EVM', net: 'non-evm', chainId: 1 },
  ];

  const { currentTheme } = useAppContext();

  useEffect(() => {
    if (isOpenMenu) {
      const temp = isOpenMenu.findIndex((el) => el.name === name);
      setIndexMenu(temp);
      setSelectedNetwork(networkOrder[0]); // set the default selected network to the first network (ETH)
    }
  }, [isOpenMenu, name]);

  // useEffect(() => {
  //   if (currentList) {
  //     setCurrentListFiltered(currentList);
  //   }
  // }, [currentList]);

  // useEffect(() => {
  //   if (currentList && currentQuery.trim() !== '') {
  //     setCurrentListFiltered(
  //       currentList.filter((el) =>
  //         el.name
  //           .toLowerCase()
  //           .trim()
  //           .includes(currentQuery.toLowerCase().trim())
  //       )
  //     );
  //   } else if (currentList && currentQuery.trim() === '') {
  //     setCurrentListFiltered(currentList);
  //   }
  // }, [currentList, currentQuery, isOpenMenu]);

  const RenderImg = ({ currency }) => {
    const [url, setUrl] = React.useState(currency.image);
    return (
      <div>
        <img
          src={url}
          alt={currency.name}
          className={` w-6 h-6`}
          onError={() => setUrl('https://cdn.onramper.com/default-token.svg')}
        />
      </div>
    );
  };

  const SimpleListElement = ({ data }) => {
    return (
      <>
        <p>{data.name}</p>
      </>
    );
  };

  const PaymentListElement = ({ data }) => {
    return (
      <>
        <div className="flex gap-3 items-center">
          {data?.img}
          <p>{data.name}</p>
        </div>
      </>
    );
  };

  const injectCode = (data) => {
    switch (type) {
      case 'currency':
        return (
          <>
            <div className="flex items-center justify-end">
              <RenderImg currency={data} />
              <p className="mr-4 ml-2">{data.name}</p>
            </div>
          </>
        );
      case 'simpleList':
        return <SimpleListElement data={data} />;
      case 'payment':
        return <PaymentListElement data={data} />;
      default:
        return null;
    }
  };

  // useEffect(() => {
  //   console.log(currentQuery);
  // }, [currentQuery]);

  return (
    <>
      {/* {currentData && ( */}
      <div className={`base:w-[100%] md:w-[50%] min-h-fit`}>
        {/* {console.log(currentData)} */}
        <h4>{title}</h4>
        <div className=" min-w-[100%] inline-block text-left cursor-pointer relative">
          <div
            className={` min-w-[100%]  h-[48px] flex justify-between items-center outline-none mt-2 rounded-lg bg-[${colorPage?.bgInput}] py-2 px-4 border border-[${colorPage?.bgInput}]`}
            onClick={() => {
              setIsOpenMenu((prev) => {
                return [
                  ...prev.map((el) => {
                    return {
                      name: el.name,
                      visible: el.name === name ? !el.visible : false,
                    };
                  }),
                ];
              });
              setCurrentQuery('');
            }}
            onBlur={() => {}}
          >
            {currentData && (
              <div className="flex gap-4 items-center">
                {injectCode(currentData)}
              </div>
            )}
            {<ArrowDownSVG />}
          </div>

          {isOpenMenu[indexMenu]?.visible && (
            <div className=" bg-white border border-slate-200 rounded-lg w-[100%] z-10 px-3 py-2 absolute">
              {withInput && (
                <>
                  <h3 className="text-center mt-2 mb-2">Select token</h3>
                  <div className="flex justify-evenly mb-5 mt-4">
                    {networkOrder.map((networkButton, index) => (
                      <button
                        key={index + '1'}
                        className={`cursor-pointer block text-base font-bold rounded-lg border px-3
              
                      `}
                        style={{
                          color: currentTheme?.text?.accent,
                          borderColor: currentTheme?.border?.passive,
                          backgroundColor:
                            networkButton.net === selectedNetwork.net
                              ? '#e2e2e2'
                              : '',
                        }}
                        onClick={() => {
                          setSelectedNetwork((prev) => {
                            return prev.net === networkButton.net
                              ? { name: '', net: '' }
                              : { ...networkButton };
                          });
                        }}
                      >
                        {networkButton?.name}
                      </button>
                    ))}
                  </div>
                  <div className=" mb-6" style={{ position: 'relative' }}>
                    <label
                      className="flex items-center justify-center"
                      style={{
                        position: 'absolute',
                        top: '9px',
                        left: '12px',
                      }}
                    >
                      <SearchSVG />
                    </label>
                    <input
                      placeholder="Search..."
                      className=" w-full outline-none border rounded-full py-2 pl-12 pr-20"
                      // value={currentQuery}
                      onChange={(e) => setCurrentQuery(e.target.value)}
                      onBlur={() => {}}
                    />
                  </div>
                </>
              )}
              {/* {console.log('currentListFiltered', currentListFiltered)} */}
              <div className=" overflow-auto max-h-[300px] flex flex-col gap-2">
                {currentData &&
                  currentList &&
                  currentList
                    .filter((el) => {
                      if (typeCurrency === 'crypto' && selectedNetwork) {
                        if (el?.chain.includes(selectedNetwork?.net)) {
                          return el;
                        } else if (
                          selectedNetwork?.net === 'non-evm' &&
                          !['eth', 'poly', 'bsc'].includes(el?.chain)
                        ) {
                          return { el };
                        }
                      } else return el;
                    })
                    .filter((el) => {
                      if (typeCurrency === 'fiat') {
                        if (
                          el?.id
                            .toLowerCase()
                            .includes(currentQuery.toLowerCase()) ||
                          el?.name
                            .toLowerCase()
                            .includes(currentQuery.toLowerCase())
                        ) {
                          return el;
                        }
                      } else {
                        if (
                          el?.ticker
                            .toLowerCase()
                            .includes(currentQuery.toLowerCase()) ||
                          el?.name
                            .toLowerCase()
                            .includes(currentQuery.toLowerCase())
                        ) {
                          return el;
                        }
                      }
                    })
                    .map((el, index) => {
                      return (
                        <div
                          className={`flex justify-between items-center gap-5 transition-all cursor-pointer rounded-lg px-4 py-2 `}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              checkedBg('#ffffff') + '26')
                          }
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor =
                              'transparent';
                          }}
                          onClick={() => {
                            dispatch(unirampActions.setCurrentCrypto(el));
                            setCurrentData(el);
                            setIsOpenMenu((prev) => {
                              return [
                                ...prev.map((el) => {
                                  return { name: el.name, visible: false };
                                }),
                              ];
                            });
                            setCurrentQuery('');
                          }}
                          key={title + index}
                        >
                          <div>{injectCode(el)}</div>
                          <div className=" items-center ">
                            {typeCurrency === 'crypto' ? (
                              el?.ticker === currentData?.ticker &&
                              el?.chain === currentData?.chain ? (
                                <div className="flex items-center  ">
                                  <p
                                    className=" text-[12px] uppercase"
                                    style={{
                                      color: currentTheme?.text?.plain,
                                    }}
                                  >
                                    {el?.chain}
                                  </p>
                                  <div
                                    className=" w-5 h-4 ml-3 "
                                    style={{
                                      color: '#000000',
                                    }}
                                  >
                                    <CheckMarkSVG />
                                  </div>
                                </div>
                              ) : (
                                <p
                                  className=" text-[12px] uppercase"
                                  style={{ color: currentTheme?.text?.plain }}
                                >
                                  {el?.chain}
                                </p>
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
}
