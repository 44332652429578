import React from 'react';
export const colorPage = {
  bgColorMain: '#FFFFFF',
  bgColorComponent: 'transparent',
  title: '#241D1C',
  text: '#241D1C',
  accent: '#0891B2',
  // bgColor: '#FFFFFF',
  // bgInput: '#F6F7F9',
  applyBtn: '#241D1C',
  commentText: '#6B7280',
  copyText: '#EF4444',
};
