import React from 'react';
import { checkedBg } from '../../services/checkedBg';
import { useSelector } from 'react-redux';
import unirampSelectors from '../../store/uniramp/uniramp.selector';

export const SpinnerRound = () => {
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  return (
    <div className="item w-[70px] h-[70px]">
      <div
        className={
          checkedBg(currentConfig?.colBg) === '#FFFFFF'
            ? 'spinnerLight'
            : 'spinnerDark'
        }
      ></div>
    </div>
  );
};
