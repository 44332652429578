import { useCallback, useEffect, useState } from 'react';
import { CloseSVG } from '../../assets/svgStore';
import {
  delayForWalletModal as delay,
  overlayMenu,
} from '../../assets/themesStorage';
import { useAppContext } from '../../contextWrapper';
import { checkedBg } from '../../services/checkedBg';

//wagmi---------------------------
import { useConnect } from 'wagmi';

export const ChoiceWalletModal = ({ isOpen, setIsOpen }) => {
  const { currentLogoColor, currentTheme, currentLocale } = useAppContext();

  //wagmi---------------------------

  const { currentConfig } = useAppContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);

  const { error } = useConnect();

  const fadeOut = useCallback(() => {
    if (setIsOpen) {
      setShowCloseBtn(false);
      setTimeout(() => {
        setShowModal(false);
      }, delay?.delay / 2);
      setTimeout(() => {
        setShowOverlay(false);
      }, delay?.delay + delay?.delay / 2);
      setIsOpen(false);
    }
  }, [setIsOpen]);

  useEffect(() => {
    isOpen ? fadeIn() : fadeOut();
  }, [fadeOut, isOpen]);

  //-------------------------------

  //-------------------------------

  const fadeIn = () => {
    setShowOverlay(true);
    setTimeout(() => {
      setShowModal(true);
    }, delay?.delay);
    setTimeout(() => {
      setShowCloseBtn(true);
    }, delay?.delay + delay?.menuInOut);
  };

  return (
    <>
      {isOpen && currentLocale && (
        <>
          <div
            className={`w-full h-full absolute bg-black top-0 left-0 z-20`}
            style={{
              visibility: showOverlay ? 'visible' : 'hidden',
              opacity: showOverlay
                ? overlayMenu?.overlayIn
                : overlayMenu?.overlayOut,
              transition: `all ${String(delay?.overlayInOut)}ms`,
            }}
            onClick={() => fadeOut()}
          ></div>
          <div
            className={`absolute rounded-lg z-30 p-4`}
            style={{
              visibility: showModal ? 'visible' : 'hidden',
              opacity: showModal ? 1 : 0,
              width: '80%',
              height: '50%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: currentConfig?.colBg,
              transition: `all ${String(delay?.overlayInOut)}ms`,
            }}
          >
            <div
              className={`absolute w-4 h-4 top-6 right-4 cursor-pointer`}
              style={{
                visibility: showCloseBtn ? 'visible' : 'hidden',
                opacity: showCloseBtn ? 1 : 0,
                transition: `all ${delay?.delay / 2}ms`,
              }}
            >
              <div
                className={` w-[100%] h-[100%] cursor-pointer flex items-center justify-center`}
                onClick={() => setIsOpen(false)}
                style={{
                  color: currentTheme.text.plain,
                }}
              >
                <CloseSVG />
              </div>
            </div>
            <div className=" w-full h-full flex flex-col gap-5 justify-center items-center">
              {/* ------------------------ */}

              <div style={{ paddingTop: '0.5rem' }} className="text-center">
                <div
                  style={{
                    fontSize: '30px',
                    margin: '0 auto',
                    color: currentLogoColor,
                  }}
                >
                  🥳
                </div>
                <div className="flex flex-col items-center mt-4">
                  <p
                    className=" text-base"
                    style={{ color: currentTheme?.text?.accent }}
                  >
                    {currentLocale['Coming soon in Q3 2023']}
                  </p>
                  <div className="flex"> </div>
                  <div
                    style={{
                      backgroundColor: currentConfig.colBtn,
                      color: checkedBg(currentConfig?.colBtn),
                    }}
                    className="mt-4 font-bold text-base py-2 px-4 border border-black-700 rounded-lg cursor-pointer transition-all easy-in-out duration-300"
                    onClick={() => {
                      const link = document.createElement('a');
                      link.href = 'https://twitter.com/uniramp';
                      link.target = '_blank';
                      link.click();
                      link.remove();
                    }}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor =
                        currentConfig?.colBtn + `80`;
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = currentConfig?.colBtn;
                    }}
                  >
                    {currentLocale['Follow us']}
                  </div>
                </div>
              </div>

              {error && (
                <p
                  className=" text-sm text-center"
                  style={{ color: checkedBg(currentConfig?.colBg) }}
                >
                  {error.message}
                </p>
              )}
              {/* ----------------------------------------------- */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
