import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAccount } from 'wagmi';
import { unirampActions } from '../store/uniramp/uniramp.slice';

export const useWalletChanged = () => {
  const dispatch = useDispatch();

  const { address: wallet } = useAccount();

  useEffect(() => {
    if (wallet) {
      dispatch(unirampActions.setIsAuthFirebase(true));
      dispatch(unirampActions.setWallet(wallet));
    } else {
      dispatch(unirampActions.setIsAuthFirebase(false));
      dispatch(unirampActions.setWallet(null));
    }
  }, [wallet, dispatch]);
};
