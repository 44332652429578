export const errorState = {
  gateway_error: {
    message: 'Transaction Route Unavailable',
  },
  maximum_gateway_error: {
    message: 'Maximum limit of ',
  },
  minimum_gateway_error: {
    message: 'Minimum limit of ',
  },
};
