import { useEffect, useState } from 'react';
import { checkedBg } from '../../services/checkedBg';
import unirampSelectors from '../../store/uniramp/uniramp.selector';
import { useSelector } from 'react-redux';

export const RenderImg = ({ currency }) => {
  const [url, setUrl] = useState(null);
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  useEffect(() => {
    setUrl(
      currency?.image
        ? currency?.image
        : 'https://cdn.onramper.com/default-token.svg'
    );
  }, [currency]);

  return (
    <div
      className={` w-7 h-7 rounded-full`}
      style={{
        borderRadius: '50%',
        backgroundColor: '#FFFFFF',
        padding: '1px',
      }}
    >
      <img
        src={url}
        alt={currency?.name ? currency?.name : ''}
        className={` w-full h-full rounded-full`}
        onError={() => setUrl('https://cdn.onramper.com/default-token.svg')}
      />
    </div>
  );
};
