export const transactionStatus = {
  new: ['Created Transaction...', ''],
  processing: [
    'Pending Transaction 🌎',
    'Your tokens are being delivered now, kindly do not refresh the page.',
  ],
  failed: [
    'Transaction Failed 😞',
    'There was an issue with the swap. Increase slippage value and retry. If error persist, please contact support.',
  ],
  success: [
    'Token Purchased 🎉',
    'Your purchased tokens are successfully in your wallet. Congratulations!',
  ],
  invalid: ['Invalid Transaction 😲', 'We don`t know, what the problem.'],
  unknown: ['Unknown Transaction 😲', 'We don`t know, what the problem.'],

  defiSwap: [
    'DeFi Swap ⚡️',
    'You are required to perform a swap to complete your token purchase. Kindly accept the transaction on your wallet.',
  ],

  processing_defi: [
    'Pending Transaction 🌎',
    'Your swap transaction is being processed, kindly do not refresh the page.',
  ],

  failed_defi: [
    'Swap Reverted 😞',
    'There was an issue with the swap. Increase slippage value and retry. If error persist, please contact support.',
  ],

  success_defi: [
    'Token Purchased 🎉',
    'Your purchased tokens are successfully in your wallet. Congratulations!',
  ],
};

export const conditionForTransactionStatusElement = {
  textShowed: [
    'new',
    'processing',
    'failed',
    'success',
    'invalid',
    'unknown',
    'defiSwap',
    'processing_defi',
    'failed_defi',
    'success_defi',
  ],
  // toplineSvg
  queryMarkSvg: [
    // 'new',
    'processing',
    // 'invalid',
    // 'failed',
    // 'unknown',
    'processing_defi',
    'failed_defi',
    // 'success_defi',
  ],
  closeBtnSvg: ['failed', 'invalid', 'unknown', 'failed_defi', 'success_defi'],
  // blockSvg
  spinnerRound: ['new', 'processing', 'processing_defi'],
  successSvg: ['success'],
  failedSvg: ['failed', 'invalid', 'unknown', 'failed_defi'],
  closeBtnCiFi: ['failed', 'invalid', 'unknown'],
  closeBtnCiFiSuccess: ['success'],
  typeHybrid: ['hybrid'],
  typeDirect: ['direct'],
  defiFailed: ['failed_defi'],
  successDeFi: ['success_defi'],
  helpLink: ['processing', 'processing_defi', 'failed_defi'],
};
