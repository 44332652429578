import { BestPriceSVG, CheckMarkSVG, MoonpaySVG } from '../assets/svgStore';
import { checkedBg } from '../services/checkedBg';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
import { unirampActions } from '../store/uniramp/uniramp.slice';
import { convertNumber } from './currencyInfoBox';
import { sizeForPopup } from '../assets/sizeForPopup';

export const MenuGateways = ({
  setIsOpen,
  localCurrentGatewayList,
  setLocalCurrentGateway,
  localCurrentGateway,
  setLocalCurrentGatewayList,
}) => {
  const dispatch = useDispatch();
  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  const currentFiat = useSelector(unirampSelectors.getCurrentFiat());
  const currentCrypto = useSelector(unirampSelectors.getCurrentCrypto());
  const serviceListGateways = useSelector(
    unirampSelectors.getServiceListGateways()
  );
  // const currentCurrency = typeCurrency === 'fiat' ? currentFiat : currentCrypto;
  const currentCurrency = currentCrypto;

  const handlerChangeGateway = (el) => {
    dispatch(unirampActions.setCurrentGatewayDec(el));
    setLocalCurrentGateway(el);
    dispatch(unirampActions.setIsClosePopup(true));
    // setIsOpen(false);
  };

  return (
    <>
      {localCurrentGateway && localCurrentGatewayList && (
        <div
          className="px-4 pb-4 pt-6"
          style={{
            width: sizeForPopup?.currencyMenu?.width,
            height: sizeForPopup?.currencyMenu?.height,
          }}
        >
          <p
            className="text-center text-base"
            style={{
              color: currentTheme.text.accent,
            }}
          >
            {currentLocale['Select gateways']}
          </p>
          <div className=" h-fit max-h-[375px] overflow-y-auto mt-5 flex flex-col gap-1">
            {localCurrentGatewayList &&
              localCurrentGatewayList?.length > 1 &&
              localCurrentGatewayList.map((item, index) => {
                let el = serviceListGateways.find(
                  (serviceEl) => serviceEl.id === item.gateway
                );
                return (
                  <div
                    key={uuid()}
                    className={`p-3 rounded-lg cursor-pointer`}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        checkedBg(currentConfig.colBg) + '26')
                    }
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                    onClick={() => {
                      handlerChangeGateway(item);
                    }}
                  >
                    <div className="flex items-center justify-between gap-4">
                      <div className=" flex gap-3 items-center">
                        <div className=" w-8 h-8">
                          {el.id === 'Moonpay'.toLowerCase() ? (
                            <div
                              className="w-8 h-8"
                              style={{ color: checkedBg(currentConfig?.colBg) }}
                            >
                              <MoonpaySVG />
                            </div>
                          ) : (
                            <img
                              src={el.image}
                              alt={el.name}
                              className="w-full h-full rounded-full p-[1px] bg-white"
                            />
                          )}
                        </div>
                        <div className="w-auto">
                          <div
                            className="font-bold"
                            style={{ color: currentTheme.text.accent }}
                          >
                            <div className="text-base">
                              {el?.name}
                              {/* {`${el?.name} ${
                          item?.type === 'direct'
                            ? ''
                            : el?.name !== 'Sandbox'
                            ? currentLocale['uniramp protocol']
                            : ''
                        }`} */}
                              {/* {el?.name === 'Sandbox' && item?.type === 'hybrid' && ( */}
                              {item?.type === 'hybrid' && (
                                <div className=" font-normal text-xs">
                                  {currentLocale['With Uniramp Protocol']}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="w-[100%] flex ">
                            <div className="w-full   items-center">
                              <div className="flex justify-between relative">
                                {/* {item?.decimalResult &&
                              item?.decimalResult ===
                                localCurrentGateway?.decimalResult && (
                                <div className="text-xs text-green-500 font-semibold  flex mt-1 ">
                                  <BestPriceSVG />
                                  <p className="ml-1">Recommended</p>
                                </div>
                              )} */}
                                {item?.status === 'Recommended' && (
                                  <div className="text-xs text-green-500 font-semibold  flex mt-1 ">
                                    <BestPriceSVG />
                                    <p className="ml-1">Recommended</p>
                                  </div>
                                )}
                              </div>
                              <div className="w-full text-xs truncate flex flex-col  ">
                                <div className="flex items-center">
                                  {item?.decimalResult && (
                                    <div className="flex items-center relative">
                                      <p
                                        className="text-xs mt-1"
                                        style={{
                                          color: currentTheme.text.plain,
                                        }}
                                      >
                                        {convertNumber(item?.decimalResult)}
                                        <span className="ml-1">
                                          {currentCurrency?.ticker?.toUpperCase()}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </div>

                                {!item.cryptoAmount && (
                                  <p
                                    className="text-xs"
                                    style={{ color: currentTheme.text.plain }}
                                  >
                                    {currentLocale?.nothing}
                                  </p>
                                )}
                              </div>
                            </div>
                            <p
                              className="text-xs"
                              style={{ color: currentTheme.text.plain }}
                            ></p>
                          </div>
                        </div>
                      </div>{' '}
                      {el?.id === localCurrentGateway?.gateway &&
                        item?.type === localCurrentGateway?.type && (
                          <div
                            className="w-5 h-4 ml-3"
                            style={{
                              color: checkedBg(currentConfig?.colBg),
                            }}
                          >
                            <CheckMarkSVG />
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};
