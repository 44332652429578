import { unirampActions } from './uniramp.slice';
import axiosService from '../api_service/axios_service';
import { unirampApi } from '../api_service/uniramp_api';
import config from '../../assets/configUnirampApi.json';
// import axios from 'axios';
import qs from 'qs';
import axios from 'axios';

const {
  supportedFiats,
  supportedCryptocurrencies,
  queryIPAddress,
  aggregateCeFiOnramps,
  aggregateDeFiOnramps,
  supportedCeFiGateways,
  createTransaction,
  supportedCeFiPayment,
} = config;

const widgetApi = unirampApi.injectEndpoints({
  endpoints: builder => ({
    getCurrentIP: builder.query({
      async queryFn(__, { dispatch }) {
        try {
          const { data } = await axiosService.get(queryIPAddress, {
            cache: 'no-cache',
          });
          console.log('data', data.fiat.decimal);
          data?.fiat && dispatch(unirampActions.setCurrentFiat(data?.fiat));
          data?.fiat &&
            dispatch(
              unirampActions.setCurrentAmount(
                data?.fiat?.placeholder / Math.pow(10, data.fiat.decimal)
              )
            );
          data?.payment?.length &&
            dispatch(unirampActions.setCurrentPaymentList(data?.payment));
          data?.payment?.length &&
            dispatch(unirampActions.setCurrentPayment(data?.payment[0]));

          const { data: listGateways } = await axiosService.get(
            supportedCeFiGateways
          );
          listGateways?.length &&
            dispatch(unirampActions.setServiceListGateways(listGateways));

          return { data };
        } catch (error) {
          return { error: error?.message };
        }
      },
    }),

    getFiatList: builder.query({
      async queryFn(__, { dispatch }) {
        try {
          const { data } = await axiosService.get(supportedFiats);
          dispatch(unirampActions.setFiatList(data));
          return data;
        } catch (error) {
          if (error?.response?.data?.error === 'Forbidden') {
            dispatch(unirampActions.setIsAuth(false));
          }
          return { error: error?.message };
        }
      },
    }),

    getCurrentFiatById: builder.query({
      async queryFn(id, { dispatch }) {
        try {
          const { data } = await axiosService.get(`${supportedFiats}/${id}`);
          const { payment, ...rest } = data;
          dispatch(unirampActions.setCurrentFiat(rest));
          dispatch(
            unirampActions.setCurrentAmount(
              rest?.placeholder / Math.pow(10, data.decimal)
            )
          );
          dispatch(unirampActions.setCurrentPaymentList(payment));
          dispatch(unirampActions.setCurrentPayment(payment[0]));
          return { data };
        } catch (error) {
          return { error: error?.message };
        }
      },
    }),

    getCryptoList: builder.query({
      async queryFn(__, { dispatch }) {
        try {
          const { data } = await axiosService.get(supportedCryptocurrencies);
          dispatch(unirampActions.setCryptoList(data));
          return { data };
        } catch (error) {
          return { error: error?.message };
        }
      },
    }),

    getCeFiOnramper: builder.query({
      async queryFn(params, { dispatch }) {
        // dispatch(unirampActions.rejectCurrentGateways());

        try {
          const { data } = await axiosService.get(
            `${aggregateCeFiOnramps}?${qs.stringify({ ...params })}`
          );
          if (data?.gateways?.length) {
            const hasDirectType = data?.gateways?.some(
              item => item.type === 'direct'
            );
            if (hasDirectType) {
              dispatch(
                unirampActions.setCurrentGatewaysList([
                  ...data?.gateways?.filter(el => el.type === 'direct'),
                ])
              );
            } else {
              dispatch(
                unirampActions.setCurrentGatewaysList([...data?.gateways])
              );
            }

            dispatch(unirampActions.setIsGatewaysListStatus('success'));
          } else {
            dispatch(unirampActions.rejectCurrentGateways());
            dispatch(unirampActions.setIsGatewaysListStatusDec('failed'));
            dispatch(unirampActions.setIsGatewaysListStatus('failed'));
          }
          return { data };
        } catch (error) {
          dispatch(unirampActions.rejectCurrentGateways());
          dispatch(unirampActions.setIsGatewaysListStatusDec('failed'));
          dispatch(unirampActions.setIsGatewaysListStatus('failed'));
          dispatch(unirampActions.setErrorCeFiPayments(error?.response?.data));
          return { error: error?.message };
        }
      },
    }),

    postTransaction: builder.mutation({
      async queryFn(body, { dispatch }) {
        dispatch(unirampActions.setSendTransactionResponse(null));
        try {
          const { data } = await axiosService.post(createTransaction, body);
          dispatch(unirampActions.setSendTransactionResponse(data));
          dispatch(unirampActions.setCurrentTransactionId(data?.id));
          return data;
        } catch (error) {
          return { error: error?.message };
        }
      },
    }),

    getDataFromDiFi: builder.query({
      async queryFn(url, { dispatch }) {
        try {
          const { data } = await axios.get(url);
          dispatch(unirampActions.setDataFromDiFi(data));
          return { data };
        } catch (error) {
          return { error: error?.message };
        }
      },
    }),

    getDiFiQuote: builder.query({
      async queryFn(params, { dispatch }) {
        try {
          const { data } = await axiosService.get(
            `${aggregateDeFiOnramps}?${qs.stringify({ ...params })}`
          );
          console.log('data getDiFi Quote', data);
          return { data };
        } catch (error) {
          console.log('error getDiFi Quote', error);
          dispatch(unirampActions.rejectCurrentGateways());
          dispatch(unirampActions.setIsGatewaysListStatusDec('failed'));
          dispatch(unirampActions.setIsGatewaysListStatus('failed'));
          return { error: error?.message };
        }
      },
    }),

    getAllCeFiPayment: builder.query({
      async queryFn(__, { dispatch }) {
        try {
          const { data } = await axiosService.get(supportedCeFiPayment);
          dispatch(unirampActions.setAllCeFiPayments(data));
          return { data };
        } catch (error) {
          return { error: error?.message };
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetCurrentIPQuery,
  useLazyGetFiatListQuery,
  useLazyGetCurrentFiatByIdQuery,
  useLazyGetCryptoListQuery,
  useLazyGetAllPaymentsQuery,
  useLazyGetCeFiOnramperQuery,
  usePostTransactionMutation,
  useLazyGetDataFromDiFiQuery,
  useLazyGetDiFiQuoteQuery,
  useLazyGetAllCeFiPaymentQuery,
} = widgetApi;
