import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentIP: null,
  currentConfig: null,
  currentLocale: null,
  currentTheme: null,
  currentAmount: null,
  currentPageBuySell: 'Buy',
  fiatList: [],
  currentFiat: null,
  cryptoList: [],
  currentCrypto: null,
  paymentList: [],
  currentPayment: null,
  isAuthFirebase: false,
  wallet: null,
  idToken: null,
  userId: null,
  isGatewaysListStatus: 'pending', // 'pending' | 'success' | 'failed
  currentGatewaysList: null,
  currentGateway: null,
  isGatewaysListStatusDec: 'pending', // 'pending' | 'success' | 'failed
  currentGatewaysListDec: null,
  currentGatewayDec: null,
  serviceListGateways: [],
  sendTransactionResponse: null,
  currentTransactionId: null,
  currentTransactionFb: null,
  isOpenIFrame: false,
  isOpenTransactionState: false,
  dataFromDiFi: null,
  serviceAllCeFiPayments: [],
  errorCeFiPayments: null,
  isAuth: true,
  apiKeyConfigPage: '',
  isClosePopup: false,
  isClose:null,
};

const unirampSlice = createSlice({
  name: 'uniramp',
  initialState,
  reducers: {
    setCurrentIP: (state, { payload }) => {
      state.currentIP = payload;
    },
    setServiceListGateways: (state, { payload }) => {
      state.serviceListGateways = payload;
    },
    setCurrentConfig: (state, { payload }) => {
      state.currentConfig = payload;
    },
    setCurrentLocale: (state, { payload }) => {
      state.currentLocale = payload;
    },
    setCurrentTheme: (state, { payload }) => {
      state.currentTheme = payload;
    },
    setCurrentAmount: (state, { payload }) => {
      state.currentAmount = payload;
    },
    setFiatList: (state, { payload }) => {
      state.fiatList = [...payload];
    },
    setCryptoList: (state, { payload }) => {
      state.cryptoList = [...payload];
      const temp =
        payload.find(
          (el) =>
            el?.address === state.currentConfig?.token &&
            el?.chain === state.currentConfig?.chain
        ) ||
        payload.find(
          (el) =>
            el?.ticker === state.currentConfig?.token &&
            el?.chain === state.currentConfig?.chain
        ) ||
        payload.find(
          (el) =>
            el?.ticker === 'eth' && el?.chain === state.currentConfig?.chain
        );

      state.currentCrypto = temp;
    },
    setCurrentPageBuySell: (state, { payload }) => {
      state.currentPageBuySell = payload;
    },
    setCurrentFiat: (state, { payload }) => {
      state.currentFiat = payload;
    },
    setCurrentCrypto: (state, { payload }) => {
      state.currentCrypto = payload;
    },
    setIsAuthFirebase: (state, { payload }) => {
      state.isAuthFirebase = payload;
    },
    setWallet: (state, { payload }) => {
      state.wallet = payload;
    },
    setIdToken: (state, { payload }) => {
      state.idToken = payload;
    },
    setUserId: (state, { payload }) => {
      state.userId = payload;
    },
    resetFbSettings: (state) => {
      state.wallet = null;
      state.idToken = null;
      state.userId = null;
      state.isAuthFirebase = false;
    },
    setCurrentPaymentList: (state, { payload }) => {
      state.paymentList = [];
      state.paymentList = [...payload];
      // state.serviceAllCeFiPayments.forEach((secondEl) => {
      //   if (
      //     !state.paymentList.some((resultObj) => resultObj.id === secondEl.id)
      //   ) {
      //     state.paymentList.push(secondEl);
      //   }
      // });
    },
    setCurrentPayment: (state, { payload }) => {
      state.currentPayment = payload;
    },
    setIsGatewaysListStatus: (state, { payload }) => {
      state.isGatewaysListStatus = payload;
    },
    setCurrentGatewaysList: (state, { payload }) => {
      state.currentGatewaysList = [...payload];
    },
    setCurrentGateway: (state, { payload }) => {
      state.currentGateway = {
        ...payload,
        ...state.serviceListGateways.find((el) => el.id === payload.gateway),
      };
    },
    setSendTransactionResponse: (state, { payload }) => {
      state.sendTransactionResponse = payload;
    },
    setCurrentTransactionId: (state, { payload }) => {
      state.currentTransactionId = payload;
    },
    setIsOpenIFrame: (state, { payload }) => {
      state.isOpenIFrame = payload;
    },
    setCurrentTransactionFb: (state, { payload }) => {
      state.currentTransactionFb = payload;
    },
    setIsOpenTransactionState: (state, { payload }) => {
      state.isOpenTransactionState = payload;
    },
    rejectTransaction: (state) => {
      state.currentTransactionFb = null;
      state.currentTransactionId = null;
      state.isOpenTransactionState = false;
      state.sendTransactionResponse = null;
    },
    setDataFromDiFi: (state, { payload }) => {
      state.dataFromDiFi = payload;
    },
    setIsGatewaysListStatusDec: (state, { payload }) => {
      state.isGatewaysListStatusDec = payload;
    },
    setCurrentGatewaysListDec: (state, { payload }) => {
      if (!state.currentGatewaysListDec?.length) {
        state.currentGatewaysListDec = [payload];
      } else {
        if (
          !state.currentGatewaysListDec?.find(
            (el) =>
              el?.type === payload?.type &&
              el?.gateway === payload?.gateway &&
              el?.gatewayDiFi === payload?.gatewayDiFi
          )
        ) {
          state.currentGatewaysListDec = [
            ...state.currentGatewaysListDec,
            payload,
          ];
        }
      }
    },
    setCurrentGatewayDec: (state, { payload }) => {
      state.currentGatewayDec = {
        ...payload,
        ...state.serviceListGateways.find((el) => el.id === payload.gateway),
      };
    },
    rejectCurrentGateways: (state) => {
      state.currentGatewaysListDec = null;
      state.currentGatewayDec = null;
      state.isGatewaysListStatusDec = 'pending';
      state.currentGatewaysList = null;
      state.currentGateway = null;
      state.isGatewaysListStatus = 'pending';
    },
    rejectCurrentGatewaysDec: (state) => {
      state.currentGatewaysListDec = null;
      state.currentGatewayDec = null;
      state.isGatewaysListStatusDec = 'pending';
    },
    setAllCeFiPayments: (state, { payload }) => {
      state.serviceAllCeFiPayments = payload;
    },
    setErrorCeFiPayments: (state, { payload }) => {
      state.errorCeFiPayments = payload;
    },
    setCurrentConfigField: (state, { payload }) => {
      state.currentConfig = { ...state.currentConfig, ...payload };
    },
    setIsAuth: (state, { payload }) => {
      state.isAuth = payload;
    },
    setApiKeyConfigPage: (state, { payload }) => {
      state.apiKeyConfigPage = payload;
    },
    setIsClosePopup: (state, { payload }) => {
      state.isClosePopup = payload;
    },
    setIsClose: (state, { payload }) => {
      state.isClose = payload;
    },
  },
});

export const unirampActions = unirampSlice.actions;

export default unirampSlice;
