import React, { useEffect } from 'react';
import { colorPage } from '../../assets/colorSet';
import {
  ArrowDownSVG,
  CheckMarkSVG,
  SearchSVG,
} from '../../../widget/assets/svgStore';
import { checkedBg } from '../../../widget/services/checkedBg';
import { v4 as uuid } from 'uuid';

export const MenuElement = ({
  title,
  currentData,
  setCurrentData = () => {},
  currentList,
  type,
  withInput = false,
  name,
  isOpenMenu,
  setIsOpenMenu,
}) => {
  const [currentQuery, setCurrentQuery] = React.useState('');
  const [currentListFiltered, setCurrentListFiltered] = React.useState([]);
  const [indexMenu, setIndexMenu] = React.useState(0);

  useEffect(() => {
    if (isOpenMenu) {
      const temp = isOpenMenu.findIndex((el) => el.name === name);

      setIndexMenu(temp);
    }
  }, [isOpenMenu, name]);
  // const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  useEffect(() => {
    if (currentList) {
      setCurrentListFiltered(currentList);
    }
  }, [currentList]);

  useEffect(() => {
    if (currentList && currentQuery.trim() !== '') {
      setCurrentListFiltered(
        currentList.filter((el) =>
          el.code
            .toLowerCase()
            .trim()
            .includes(currentQuery.toLowerCase().trim())
        )
      );
    } else if (currentList && currentQuery.trim() === '') {
      setCurrentListFiltered(currentList);
    }
  }, [currentList, currentQuery, isOpenMenu]);

  const RenderImg = ({ currency }) => {
    const [url, setUrl] = React.useState(currency.icon);
    return (
      <div>
        <img
          src={url}
          alt={currency.name}
          className={` w-6 h-6`}
          onError={() => setUrl('https://cdn.onramper.com/default-token.svg')}
        />
      </div>
    );
  };

  const CurrencyElement = ({ data }) => {
    return (
      <>
        <RenderImg currency={data} />

        <p>{data.code}</p>
      </>
    );
  };

  const SimpleListElement = ({ data }) => {
    return (
      <>
        <p>{data.name}</p>
      </>
    );
  };

  const PaymentListElement = ({ data }) => {
    return (
      <>
        <div className="flex gap-3 items-center">
          {data?.img}
          <p>{data.name}</p>
        </div>
      </>
    );
  };

  const injectCode = (data) => {
    switch (type) {
      case 'currency':
        return (
          <>
            <div className="flex items-center justify-end">
              <RenderImg currency={data} />
              <p className="mr-4 ml-2">{data.code}</p>
            </div>
          </>
        );
      case 'simpleList':
        return <SimpleListElement data={data} />;
      case 'payment':
        return <PaymentListElement data={data} />;
      default:
        return null;
    }
  };

  return (
    <>
      {currentData && (
        <div className={`base:w-[100%] md:w-[50%] min-h-fit`}>
          <h4>{title}</h4>
          <div className=" min-w-[100%] inline-block text-left cursor-pointer relative">
            <div
              className={` min-w-[100%]  h-[48px] flex justify-between items-center outline-none mt-2 rounded-lg bg-[${colorPage?.bgInput}] py-2 px-4 border border-[${colorPage?.bgInput}]`}
              onClick={() => {
                setIsOpenMenu((prev) => {
                  return [
                    ...prev.map((el) => {
                      return {
                        name: el.name,
                        visible: el.name === name ? !el.visible : false,
                      };
                    }),
                  ];
                });
                setCurrentQuery('');
              }}
              onBlur={() => {}}
            >
              <div className="flex gap-4 items-center">
                {injectCode(currentData)}
              </div>
              {<ArrowDownSVG />}
            </div>

            {isOpenMenu[indexMenu]?.visible && (
              <div className=" bg-white border border-slate-200 rounded-lg w-[100%] z-10 px-3 py-2 absolute">
                {withInput && (
                  <>
                    <label style={{ position: 'absolute', top: 32, left: 30 }}>
                      <SearchSVG />
                    </label>
                    <input
                      placeholder="Search..."
                      className=" w-full outline-none border rounded-full py-2 pl-12 pr-20 my-4"
                      // value={currentQuery}
                      onChange={(e) => setCurrentQuery(e.target.value)}
                      onBlur={() => {}}
                    />
                  </>
                )}

                <div className=" overflow-auto max-h-[300px] flex flex-col gap-2">
                  {currentListFiltered &&
                    currentListFiltered.map((el, index) => {
                      return (
                        <div
                          className={`flex justify-between item-center transition-all cursor-pointer p-2 rounded-lg items-center`}
                          // style={{ color: currentTheme.text.accent }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              checkedBg('#ffffff') + '26')
                          }
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor =
                              'transparent';
                          }}
                          // className={`flex justify-between items-center gap-5 transition-all cursor-pointer rounded-lg hover:bg-slate-400 px-4 py-2 `}
                          onClick={() => {
                            setCurrentData(el);
                            setIsOpenMenu((prev) => {
                              return [
                                ...prev.map((el) => {
                                  return { name: el.name, visible: false };
                                }),
                              ];
                            });
                            setCurrentQuery('');
                          }}
                          key={uuid()}
                        >
                          <div>{injectCode(el)}</div>
                          <div className=" items-center ">
                            {el === currentData && (
                              <div className=" w-5 h-4">
                                <CheckMarkSVG />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
