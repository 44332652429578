import { useEffect, useState } from 'react';
import { leftMenuLinks } from '../../assets/leftMenuLinks';
import {
  ArrowRightSVG,
  AvatarIconDefault,
  ChangeChainSVG,
  UserSVG,
} from '../../assets/svgStore';
import { checkedBg } from '../../services/checkedBg';
import { useAppContext } from '../../contextWrapper';
import { useAccountModal, useChainModal } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import TransactionHistory from '../TransactionHistory';
import { Buffer } from 'buffer';
import { useDispatch, useSelector } from 'react-redux';
import unirampSelectors from '../../store/uniramp/uniramp.selector';
import { sizeForPopup } from '../../assets/sizeForPopup';
import { unirampActions } from '../../store/uniramp/uniramp.slice';

// @js-ignore
window.Buffer = Buffer;

export const LeftMenuLinks = ({ isOpenMenu }) => {
  const dispatch = useDispatch();

  const currentConfig = useSelector(unirampSelectors.getCurrentConfig());
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  const { openAccountModal } = useAccountModal();
  const { address, status } = useAccount();
  const [links, setLinks] = useState(null);

  useEffect(() => {
    setLinks(leftMenuLinks);
  }, []);

  const [isOpenMenuAvatar, setIsOpenMenuAvatar] = useState(false);
  const [isOpenTransactionHistory, setIsOpenTransactionHistory] =
    useState(false);

  const onClick = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noreferrer';
    link.click();
    link.remove();
  };

  useEffect(() => {
    if (!isOpenMenu) {
      setIsOpenTransactionHistory(false);
    }
  }, [isOpenMenu]);

  useEffect(() => {
    if (status === 'disconnected') {
      setIsOpenMenuAvatar(false);
    }
  }, [status]);

  return (
    <>
      {isOpenTransactionHistory && (
        <>
          <div
            style={{
              color: checkedBg(currentConfig?.colBg),
              transform: 'rotate(180deg)',
              position: 'absolute',
              top: 31,
              left: 17,
              cursor: 'pointer',
            }}
            onClick={() => setIsOpenTransactionHistory(false)}
          >
            <ArrowRightSVG />
          </div>
          <TransactionHistory />
        </>
      )}
      {/* ---------------------------------------------------------------------------------------------------------------------------------- */}
      {sizeForPopup && (
        <div
          className="p-4 pt-5"
          style={{
            height: sizeForPopup?.leftMenu?.height,
            width: sizeForPopup?.leftMenu?.width,
          }}
        >
          {!isOpenTransactionHistory && links && (
            <>
              <p
                className=" text-base font-bold mb-10 text-left px-4"
                style={{ color: currentTheme?.text?.accent }}
              >
                {links.menuTitle}
              </p>

              <div className=" flex flex-col gap-2">
                {links?.menuList?.map((el) => {
                  return (
                    <div
                      key={el?.title}
                      alt={el?.title}
                      className={` flex gap-3 justify-between items-center px-4 py-2 rounded-lg translate-all duration-300 ease-in-out cursor-pointer`}
                      onClick={() => {
                        onClick(el?.url);
                        dispatch(unirampActions.setIsClosePopup(true));
                      }}
                      style={{ color: currentTheme?.text?.plain }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor =
                          checkedBg(currentConfig?.colBg) + '30';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                      }}
                    >
                      <div className="flex gap-3 items-center">
                        <div
                          style={{
                            width: '2rem',
                            height: '2rem',
                            borderRadius: '50%',
                            backgroundColor: currentTheme.bg.section,
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: 4,
                              left: 4,
                              color: checkedBg(currentConfig?.colBg),
                            }}
                          >
                            {el?.icon}
                          </div>
                        </div>
                        <p
                          className="text-base text-left"
                          style={{ color: checkedBg(currentConfig?.colBg) }}
                        >
                          {el?.title}
                        </p>
                      </div>
                      <div style={{ color: checkedBg(currentConfig?.colBg) }}>
                        <ArrowRightSVG />
                      </div>
                    </div>
                  );
                })}

                {address && (
                  <>
                    {/* <div
                    onClick={() => setIsOpenTransactionHistory(true)}
                    style={{
                      position: 'relative',
                      visibility: isOpenMenu ? 'visible' : 'hidden',

                      transform: isOpenMenu
                        ? 'translateX(0)'
                        : 'translateX(200%)',
                      transition: `transform 300ms`,
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor =
                        checkedBg(currentConfig?.colBg) + '26';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                    className={` flex gap-3 justify-between items-center px-4 py-3 rounded-lg translate-all duration-300 ease-in-out cursor-pointer`}
                  >
                    <div
                      style={{
                        color: checkedBg(currentConfig?.colBg),
                        width: '2rem',
                        height: '2rem',
                        borderRadius: '50%',
                        backgroundColor: currentTheme.bg.section,
                        position: 'absolute',
                        top: '8px',
                        left: ' 16px',
                      }}
                    >
                      <div
                        style={{
                          top: '4px',
                          left: ' 4px',
                          position: 'absolute',
                        }}
                      >
                        <AvatarIconDefault />
                      </div>
                    </div>
                    <p
                      className="ml-11 text-base"
                      style={{ color: currentTheme?.text?.plain }}
                    >
                      {currentLocale?.TransactionsHistory}
                    </p>
                    <div
                      style={{
                        color: checkedBg(currentConfig?.colBg),
                        top: 18,
                        right: 16,
                      }}
                      className="absolute"
                    >
                      <ArrowRightSVG />
                    </div>
                  </div> */}

                    {/* ----------------------------------------------- */}
                    {/* <div
                    onClick={openChainModal}
                    style={{
                      position: 'relative',
                      visibility: isOpenMenu ? 'visible' : 'hidden',

                      transform: isOpenMenu
                        ? 'translateX(0)'
                        : 'translateX(200%)',
                      transition: `transform 300ms`,
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor =
                        checkedBg(currentConfig?.colBg) + '30';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                    className={` flex gap-3 justify-between items-center px-4 py-3 rounded-lg translate-all duration-300 ease-in-out cursor-pointer`}
                  >
                    <div
                      style={{
                        color: checkedBg(currentConfig?.colBg),
                        width: '2rem',
                        height: '2rem',
                        borderRadius: '50%',
                        backgroundColor: currentTheme.bg.section,
                        position: 'absolute',
                        top: '8px',
                        left: ' 16px',
                      }}
                    >
                      <div
                        style={{
                          top: '4px',
                          left: ' 4px',
                          position: 'absolute',
                        }}
                      >
                        <ChangeChainSVG />
                      </div>
                    </div>
                    <p
                      className="ml-11 text-base"
                      style={{ color: currentTheme?.text?.plain }}
                    >
                      {currentLocale?.OpenChain}
                    </p>
                    <div
                      style={{
                        color: checkedBg(currentConfig?.colBg),
                        top: 18,
                        right: 16,
                      }}
                      className="absolute"
                    >
                      <ArrowRightSVG />
                    </div>
                  </div> */}
                    <div
                      onClick={openAccountModal}
                      // onClick={() => disconnect()}
                      style={{
                        position: 'relative',
                        visibility: isOpenMenu ? 'visible' : 'hidden',

                        transform: isOpenMenu
                          ? 'translateX(0)'
                          : 'translateX(200%)',
                        transition: `transform 300ms`,
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor =
                          checkedBg(currentConfig?.colBg) + '30';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                      }}
                      className={` flex gap-3 justify-between items-center px-4 py-3 rounded-lg translate-all duration-300 ease-in-out cursor-pointer`}
                    >
                      <div
                        style={{
                          color: checkedBg(currentConfig?.colBg),
                          width: '2rem',
                          height: '2rem',
                          borderRadius: '50%',
                          backgroundColor: currentTheme.bg.section,
                          position: 'absolute',
                          top: '8px',
                          left: ' 16px',
                        }}
                      >
                        <div
                          style={{
                            top: '4px',
                            left: ' 4px',
                            position: 'absolute',
                          }}
                        >
                          {/* поменять */}
                          <UserSVG />
                        </div>
                      </div>
                      <p
                        className="ml-11 text-base"
                        style={{ color: checkedBg(currentConfig?.colBg) }}
                      >
                        {currentLocale?.OpenAccount}
                        {/* Account */}
                      </p>
                      <div
                        style={{
                          color: checkedBg(currentConfig?.colBg),
                          top: 18,
                          right: 16,
                        }}
                        className="absolute"
                      >
                        <ArrowRightSVG />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* ------------------------------------------- */}
            </>
          )}
        </div>
      )}
    </>
  );
};
