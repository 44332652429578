import React, { useEffect } from 'react';

import { useAppContext } from '../contextWrapper';
import { checkedBg } from '../services/checkedBg';
import { useRef } from 'react';
import { useAccount } from 'wagmi';

import { useAccountModal, useChainModal } from '@rainbow-me/rainbowkit';
import Transaction from './Transaction';

export default function TransactionHistory({
  isOpenMenuAvatar,
  setIsOpenMenuAvatar,
}) {
  const { currentTheme, currentConfig, currentLocale } = useAppContext();

  //rainbow ------------------------------
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  const { address, status } = useAccount();

  useEffect(() => {
    if (status === 'disconnected') {
      setIsOpenMenuAvatar(false);
    }
  }, [status]);

  const buttonStyles = {
    backgroundColor: currentConfig.colBtn,
    color: checkedBg(currentConfig?.colBtn),
  };

  return (
    <>
      <div className=" pb-4 pt-6 px-4 flex flex-col justify-between w-[100%]">
        <h3
          className="pb-2 text-center text-base"
          style={{ color: currentTheme.text.plain }}
        >
          {currentLocale?.TransactionsHistory}
        </h3>
        {/* <div className=" w-full px-4">
          <p
            className=" whitespace-nowrap overflow-hidden text-ellipsis text-xs pb-2"
            style={{ color: currentTheme.text.plain }}
          >
            {currentLocale?.Adress} {address}
          </p>
        </div> */}
        <div className=" overflow-y-scroll " style={{ height: '430px' }}>
          {address && <Transaction />}
        </div>

        {/* <div
          className="  px-2 pt-4  flex justify-evenly item-center"
          style={{
            visibility: isOpenMenuAvatar ? 'visible' : 'hidden',
            transform: isOpenMenuAvatar ? 'translateX(0)' : 'translateX(200%)',
            transition: `transform 300ms`,
          }}
        > */}
        {/* <button
            style={buttonStyles}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                currentConfig.colBtn + '80')
            }
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = currentConfig.colBtn;
            }}
            className=" rounded-lg hover:focus:outline-none transition-all duration-300 ease-in-out flex justify-center items-center px-4 py-2"
            onClick={openAccountModal}
          >
            <p
              className=""
              style={{
                fontSize: '12px',
              }}
            >
              {currentLocale?.OpenAccount}
            </p>
          </button>
          <button
            style={buttonStyles}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                currentConfig.colBtn + '80')
            }
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = currentConfig.colBtn;
            }}
            className="rounded-lg hover:focus:outline-none transition-all duration-300 ease-in-out flex justify-center items-center px-4 py-2"
            onClick={openChainModal}
          >
            <p
              style={{
                fontSize: '12px',
              }}
            >
              {currentLocale?.OpenChain}
            </p>
          </button> */}
        {/* </div> */}
      </div>
    </>
  );
}
