import React from 'react';
import en from '../locales/en.json';
import { LegalSVG, PrivacyPolicySVG, PrivacyPolicySVGv2, SupportSVG, SupportSVGv2, TermsAndUsageSVG, TermsAndUsageSVGv2 } from './svgStore';

export const leftMenuLinks = () => {
  return {
    menuTitle: en['Menu Links'],
    menuList: [
      {
        title: en['Menu Links List'][0],
        url: 'https://uniramp.com/terms-of-service/',
        icon:<TermsAndUsageSVGv2 />,
      },
      {
        title: en['Menu Links List'][1],
        url: 'https://uniramp.com/privacy/',
        icon: <PrivacyPolicySVGv2 /> ,
      },
      {
        title: en['Menu Links List'][2],
        url: 'https://uniramp.com/disclaimer/',
        icon: <LegalSVG />,
      },
      {
        title: en['Menu Links List'][3],
        url: 'https://support.uniramp.com/',
        icon: <SupportSVGv2 />,
      },
    ],
  };
};
