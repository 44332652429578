const getCurrentIP = () => state => state.uniramp.currentIP;
const getServiceListGateways = () => state => state.uniramp.serviceListGateways;

const getCurrentConfig = () => state => state.uniramp.currentConfig;
const getCurrentLocale = () => state => state.uniramp.currentLocale;
const getCurrentTheme = () => state => state.uniramp.currentTheme;

// const getInitialState = createSelector(
//   [currentConfig, currentLocale, currentTheme, currentAmount],
//   (currentConfig, currentLocale, currentTheme, currentAmount) => {
//     return {
//       currentConfig: currentConfig,
//       currentLocale: currentLocale,
//       currentTheme: currentTheme,
//       currentAmount: currentAmount,
//     };
//   }
// );

// const getInitialState = () => (state) => {
//   return {
//     currentConfig: state.uniramp.currentConfig,
//     currentLocale: state.uniramp.currentLocale,
//     currentTheme: state.uniramp.currentTheme,
//     // currentAmount: state.uniramp.currentAmount,
//   };
// };

const getPageBySell = () => state => state.uniramp.currentPageBuySell;
const getFiatList = () => state => state.uniramp.fiatList;
const getCurrentFiat = () => state => state.uniramp.currentFiat;
const getCryptoList = () => state => state.uniramp.cryptoList;
const getCurrentCrypto = () => state => state.uniramp.currentCrypto;
const getCurrentAmount = () => state => state.uniramp.currentAmount;
const getIsAuthFirebase = () => state => state.uniramp.isAuthFirebase;
const getWallet = () => state => state.uniramp.wallet;
const getIdToken = () => state => state.uniramp.idToken;
const getUserId = () => state => state.uniramp.userId;
const getPaymentList = () => state => state.uniramp.paymentList;
const getCurrentPayment = () => state => state.uniramp.currentPayment;
const isGatewaysListStatus = () => state => state.uniramp.isGatewaysListStatus;
const getGatewaysList = () => state => state.uniramp.currentGatewaysList;
const getCurrentGateway = () => state => state.uniramp.currentGateway;
const getSendTransactionResponse = () => state =>
  state.uniramp.sendTransactionResponse;
const getCurrentTransactionId = () => state =>
  state.uniramp.currentTransactionId;
const getIsOpenIFrame = () => state => state.uniramp.isOpenIFrame;
const getCurrentTransactionFb = () => state =>
  state.uniramp.currentTransactionFb;
const getIsOpenTransactionState = () => state =>
  state.uniramp.isOpenTransactionState;
const getDataFromDiFi = () => state => state.uniramp.dataFromDiFi;
const getIsCurrentGatewaysListDec = () => state =>
  state.uniramp.isGatewaysListStatusDec;
const getCurrentGatewaysListDec = () => state =>
  state.uniramp.currentGatewaysListDec;
const getCurrentGatewayDec = () => state => state.uniramp.currentGatewayDec;
const getAllCeFiPayments = () => state => state.uniramp.serviceAllCeFiPayments;
const getErrorCeFiPayments = () => state => state.uniramp.errorCeFiPayments;
const getIsAuth = () => state => state.uniramp.isAuth;
const getApiKeyConfigPage = () => state => state.uniramp.apiKeyConfigPage;
const getIsClosePopup = () => state => state.uniramp.isClosePopup;
const getIsClose = () => state => state.uniramp.isClose;

const unirampSelectors = {
  getCurrentIP,
  getServiceListGateways,
  getCurrentConfig,
  getCurrentLocale,
  getCurrentTheme,
  // getInitialState,
  getPageBySell,
  getFiatList,
  getCurrentFiat,
  getCryptoList,
  getCurrentCrypto,
  getCurrentAmount,
  getIsAuthFirebase,
  getWallet,
  getIdToken,
  getUserId,
  getPaymentList,
  getCurrentPayment,
  isGatewaysListStatus,
  getGatewaysList,
  getCurrentGateway,
  getSendTransactionResponse,
  getCurrentTransactionId,
  getIsOpenIFrame,
  getCurrentTransactionFb,
  getIsOpenTransactionState,
  getDataFromDiFi,
  getIsCurrentGatewaysListDec,
  getCurrentGatewaysListDec,
  getCurrentGatewayDec,
  getAllCeFiPayments,
  getErrorCeFiPayments,
  getIsAuth,
  getApiKeyConfigPage,
  getIsClosePopup,
  getIsClose,
};

export default unirampSelectors;
