import React from 'react';

export const checkedBg = (color) => {
  const r = parseInt(color.substring(1, 3), 16) / 255;
  const g = parseInt(color.substring(3, 5), 16) / 255;
  const b = parseInt(color.substring(5, 7), 16) / 255;

  const cMin = Math.min(r, g, b);
  const cMax = Math.max(r, g, b);
  const delta = cMax - cMin;

  let hue = 0;
  if (delta === 0) {
    hue = 0;
  } else if (cMax === r) {
    hue = ((g - b) / delta) % 6;
  } else if (cMax === g) {
    hue = (b - r) / delta + 2;
  } else {
    hue = (r - g) / delta + 4;
  }
  hue = Math.round(hue * 60);

  const lightness = (cMax + cMin) / 2;

  // const saturation =
  //   delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  // return { hue, saturation, lightness };

  if ((hue < 0.55 && lightness >= 0.5) || (hue >= 0.55 && lightness >= 0.75)) {
    return '#000000'; // черный
  } else {
    return '#FFFFFF'; // белый
  }
};
