import { configureChains, createClient } from 'wagmi';
import { arbitrum, bsc, optimism, polygon, mainnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';
import {
  ALCHEMY_PROVIDER_APIKEY,
  INFURA_PROVIDER_APIKEY,
} from '../assets/apiKeys';
import { getDefaultWallets } from '@rainbow-me/rainbowkit';

export const createHTTPClient = () => {
  //------------rainboy ---------------------
  const { chains, provider, webSocketPublicClient } = configureChains(
    [mainnet, arbitrum, bsc, optimism, polygon],
    [
      alchemyProvider({
        apiKey: ALCHEMY_PROVIDER_APIKEY,
        stallTimeout: 1000,
      }),
      infuraProvider({
        apiKey: INFURA_PROVIDER_APIKEY,
        stallTimeout: 1000,
      }),
      publicProvider(),
    ]
  );

  const projectId = '9fbc9f9f747ec76ef6d184e785d0bb77';
  const { connectors } = getDefaultWallets({
    appName: 'uniramp-beta',
    projectId,
    chains,
  });

  const wagmiClient = createClient({
    // autoConnect: true,
    connectors,
    provider,
    // publicClient,
    webSocketPublicClient,
  });

  return { wagmiClient, chains, connectors };
  //-------------------------------------------
};
