import React from 'react';
import { useSelector } from 'react-redux';
import unirampSelectors from '../store/uniramp/uniramp.selector';
export default function TitleBox() {
  const currentLocale = useSelector(unirampSelectors.getCurrentLocale());
  const currentTheme = useSelector(unirampSelectors.getCurrentTheme());
  return (
    <div
      className={` w-full mb-4 text-base text-left`}
      style={{ color: currentTheme.text.plain }}
    >
      {currentLocale['Payment Method']}
    </div>
  );
}
